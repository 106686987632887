import React from "react";
import './App.css';
import AppRoutes from "./components/Route/route.jsx";

function App() {
  return (
    <div>
    <AppRoutes/>
  </div>
  );
}

export default App;
