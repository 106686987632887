import React, { useState, useEffect } from 'react';
import {
    Dialog,DialogTitle,DialogContent,DialogActions,TextField,Button,Checkbox,FormControlLabel,Grid,CircularProgress,Snackbar,Alert,
    InputLabel,Select,MenuItem,FormControl} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';


const apiurl = process.env.REACT_APP_API_URL;

const style = {
    width: '100%',
    boxSizing: 'border-box',
    height: '40px',
};

const AddProductDialog = ({ open, onClose, onAddProduct }) => {
    const [productName, setProductName] = useState('');
    const [category, setCategory] = useState('');
    const [addToPurchase, setAddToPurchase] = useState(false);
    const [orderNumber, setOrderNumber] = useState('');
    const [partyName, setPartyName] = useState('');
    const [pieces, setPieces] = useState('');
    const [rate, setRate] = useState('');
    const [amount, setAmount] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if (addToPurchase && pieces && rate) {
            const calculatedAmount = parseFloat(pieces) * parseFloat(rate);
            setAmount(calculatedAmount.toFixed(2));
        }
    }, [addToPurchase, pieces, rate]);

    const showAlert = (message, severity) => {
        setAlertInfo({ open: true, message, severity });
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertInfo({ ...alertInfo, open: false });
    };

    const resetForm = () => {
        setProductName('');
        setCategory('');
        setAddToPurchase(false);
        setOrderNumber('');
        setPartyName('');
        setPieces('');
        setRate('');
        setAmount('');
    };

    const handleSubmit = async () => {
        if (!productName || !category) {
            showAlert('Please fill in all required fields.', 'error');
            return;
        }

        setIsLoading(true);
        const newProduct = {
            productName,
            category,
            addToPurchase,
            orderNumber: addToPurchase ? orderNumber : '',
            partyName: addToPurchase ? partyName : '',
            pieces: parseInt(addToPurchase ? pieces : '0'),
            rate: parseFloat(addToPurchase ? rate : '0'),
            amount: parseFloat(addToPurchase ? amount : '0'),
        };

        try {
            const response = await fetch(`${apiurl}salesInvoice/multi`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(newProduct),
            });

            if (!response.ok) {
                throw new Error('Failed to add product');
            }

            const data = await response.json();
            onAddProduct(data);
            showAlert('Product added successfully!', 'success');
            resetForm();
            onClose();
        } catch (error) {
            console.error('Error adding product:', error);
            showAlert(`Failed to add product: ${error.message}`, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const fetchCategory = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${apiurl}category`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch categories');
            }
            const data = await response.json();
            console.log('Categories data:', data);
            if (data.status === 'success' && Array.isArray(data.categories)) {
                setCategories(data.categories);
            } else {
                console.error('Unexpected data format for categories:', data);
                setCategories([]);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
            setCategories([]);
        }
    };

    useEffect(() => {
        fetchCategory();
    }, []);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    sx: {
                        maxWidth: 'auto',
                        maxHeight: 'auto',
                        margin: 'auto',
                        mt: 0, // Set the margin-top to 0
                    },
                }}
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start', // Align dialog to the top
                    justifyContent: 'center',
                }}
            >

                <DialogTitle>Add New Product</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Product Name"
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                                required
                                InputProps={{
                                    style: {
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        height: '40px',
                                    },
                                }}
                                sx={{
                                    marginTop: "10px",
                                    '& .MuiInputLabel-root': {
                                        top: '-6px',
                                        color: '#555',
                                        '&.Mui-focused': {
                                            color: '#009999',
                                        },
                                        // Hide the default asterisk and apply custom styles
                                        '& .MuiFormLabel-asterisk': {
                                            color: 'red', // Customize the color of the asterisk
                                        },
                                    },
                                    '& .MuiInputBase-root': {
                                        color: '#000', // Text color
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ marginTop: "10px" }}>
                                <InputLabel sx={{ top: '-6px', color: '#555', '&.Mui-focused': { color: '#009999' } }}>Category</InputLabel>
                                <Select
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    label="Category"
                                    style={style}
                                >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    {categories.map((category) => (
                                        <MenuItem key={category._id} value={category.name}>
                                            {category.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={addToPurchase}
                                        onChange={(e) => setAddToPurchase(e.target.checked)}
                                    />
                                }
                                label="Add Purchase Data"
                            />
                        </Grid>
                        {addToPurchase && (
                            <>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Order Number"
                                        value={orderNumber}
                                        onChange={(e) => setOrderNumber(e.target.value)}
                                        InputProps={{
                                            style: {
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                height: '40px',
                                            },
                                        }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '-6px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000', // Adjust text color if needed
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Vendor Name"
                                        value={partyName}
                                        onChange={(e) => setPartyName(e.target.value)}
                                        InputProps={{
                                            style: {
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                height: '40px',
                                            },
                                        }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '-6px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000', // Adjust text color if needed
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Pieces"
                                        type="number"
                                        value={pieces}
                                        onChange={(e) => setPieces(e.target.value)}
                                        InputProps={{
                                            style: {
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                height: '40px',
                                            },
                                        }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '-6px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000', // Adjust text color if needed
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Rate"
                                        type="number"
                                        value={rate}
                                        onChange={(e) => setRate(e.target.value)}
                                        InputProps={{
                                            style: {
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                height: '40px',
                                            },
                                        }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '-6px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000', // Adjust text color if needed
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Amount"
                                        type="number"
                                        value={amount}
                                        InputProps={{
                                            disabled: true,
                                            style: {
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                height: '40px',
                                            },
                                        }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '-6px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000', // Adjust text color if needed
                                            },
                                        }}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                 
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        sx={{
                            backgroundColor: '#009999',
                            color: '#fff',
                            textTransform: 'capitalize',
                            '&:hover': {
                                backgroundColor: '#01dcb8',
                            },
                        }}
                        disabled={isLoading}
                        startIcon={<AddIcon />}

                    >
                        {isLoading ? <CircularProgress size={24} /> : 'Add Product'}
                    </Button>

                    <Button variant="contained" 
                    onClick={handleClose} 
                    color="error" 
                    disabled={isLoading}
                    startIcon={<CloseIcon />}
                    >Cancel</Button>
                    
                </DialogActions>
            </Dialog>

            <Snackbar
                open={alertInfo.open}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseAlert} severity={alertInfo.severity} sx={{ width: '100%' }}>
                    {alertInfo.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default AddProductDialog;