import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  FormHelperText,
} from '@mui/material';
import { MdImage, MdAdd } from 'react-icons/md';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import AddIcon from '@mui/icons-material/Add';
 
const apiurl = process.env.REACT_APP_API_URL;
 
 
const style = {
  width: '100%',
  boxSizing: 'border-box',
  height: '40px',
};
 
const DialogProduct = ({
  showForm,
  onClose,
  onSubmit,
  editIndex,
  state,
  imageFileName,
  handleInputChange,
  dispatch,
  handleImageChange,
  categories,
  setCategories,
}) => {
  const isEditing = editIndex !== null;
 
 
  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };
 
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });
 
  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
    setTimeout(() => setAlertInfo({ open: false, message: '', severity: 'success' }), 5000); // Close after 5 seconds
  };
 
  const [showCategoryDialog, setShowCategoryDialog] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
 
 
  const fetchCategories = useCallback(async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiurl}category`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }
      const data = await response.json();
      if (data.status === 'success' && Array.isArray(data.categories)) {
        setCategories(data.categories);
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      setCategories([]);
    }
  }, [setCategories]);
 
  useEffect(() => {
    if (showForm && categories.length === 0) {
      fetchCategories();
    }
  }, [showForm, categories.length, fetchCategories]);
 
  const handleAddCategory = async () => {
    if (newCategory.trim() !== '') {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${apiurl}category`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name: newCategory }),
        });
        if (!response.ok) {
          throw new Error('Failed to add category');
        }
        const data = await response.json();
        if (data.status === 'success') {
          const newCategoryObject = {
            categoryId: data?.categoryId,
            name: data?.name,
          };
          setCategories((prevCategories) => [...prevCategories, newCategoryObject]);
 
          // Set the form value to the new category name
          dispatch({
            type: 'SET_FORM_VALUES',
            payload: { category: newCategoryObject.name },
          });
          // Set the category field to the newly added category
          handleInputChange({ target: { name: 'category', value: newCategoryObject.name } });
 
          setNewCategory('');
          setShowCategoryDialog(false);
          showAlert('Category added successfully!', 'success');
        } else {
          showAlert('Failed to add category. Please try again.', 'error');
        }
      } catch (error) {
        console.error('Error adding category:', error);
        showAlert('Failed to add category. Please try again.', 'error');
      }
    }
  };
 
  return (
 
    <Dialog
      open={showForm}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: 'auto',
          maxHeight: 'auto',
          margin: 'auto',
          mt: 0, // Set the margin-top to 0
        },
      }}
      sx={{
        display: 'flex',
        alignItems: 'flex-start', // Align dialog to the top
        justifyContent: 'center',
      }}
 
    >
      <DialogTitle>{isEditing ? 'Update Product' : 'Add Product'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isEditing ? 'Update the details of the existing product.' : 'Fill in the details of the new product.'}
        </DialogContentText>
 
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                name="productName"
                label="Product Name"
                value={state.formValues.productName || ''}
                onChange={handleInputChange}
                error={!!state.errors.productName}
                helperText={state.errors.productName}
                InputProps={{
                          style: {
                            width: '100%',
                            boxSizing: 'border-box',
                            height: '40px',
                          },
                        }}
                fullWidth
                required
                sx={{
                  '& .MuiInputLabel-root': {
                    top: '-6px',
                    color: '#555',
                    '&.Mui-focused': {
                      color: '#009999',
                    },
                    // Hide the default asterisk and apply custom styles
                    '& .MuiFormLabel-asterisk': {
                      color: 'red', // Customize the color of the asterisk
                    },
                  },
                  '& .MuiInputBase-root': {
                    color: '#000', // Text color
                  },
                }}
              />
            </Grid>
 
            <Grid item xs={6}>
              <FormControl fullWidth error={!!state.errors.category} >
                <InputLabel sx={{ top: '-6px', color: '#555', '&.Mui-focused': { color: '#009999' } }}>Category <span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                  name="category"
                  value={state.formValues.category || ''}
                  style={style}
                  onChange={handleInputChange}
                  label="Category"
                  endAdornment={
                    <IconButton onClick={() => setShowCategoryDialog(true)}>
                      <MdAdd />
                    </IconButton>
                  }
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {Array.isArray(categories) &&
                    categories.map((category) => (
                      <MenuItem key={category.categoryId} value={category.name}>
                        {category.name}
                      </MenuItem>
                    ))}
                </Select>
                {state.errors.category && (
                  <FormHelperText>{state.errors.category}</FormHelperText>
                )}
              </FormControl>
            </Grid>
 
            <Grid item xs={6}>
              <TextField
                label="Image"
                value={imageFileName}
                InputProps={{
                  endAdornment: (
                    <IconButton component="label">
                      <MdImage />
                      <input type="file" accept="image/*" hidden onChange={handleImageChange} />
                    </IconButton>
                  ),
                  style: {
                            width: '100%',
                            boxSizing: 'border-box',
                            height: '40px',
                          },
                }}
                fullWidth
                sx={{
                            '& .MuiInputLabel-root': {
                              top: '-6px',
                              color: '#555',
                              '&.Mui-focused': {
                              color: '#009999',
                              },
                            },
                            '& .MuiInputBase-root': {
                              color: '#000', // Adjust text color if needed
                            },
                            }}
              />
            </Grid>
          </Grid>
          <DialogActions sx={{ mt: 2, justifyContent: 'flex-start' }}>
            <Button variant="contained" sx={{
              backgroundColor: '#009999',
              color: '#fff',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#01dcb8',
              },
            }}
              type="submit"
              startIcon={<AddIcon />}
 
            >
 
              {isEditing ? 'Update Product' : 'Add Product'}
            </Button>
            <Button variant="contained" sx={{
              backgroundColor: '#e32e2e',
              color: "fff",
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#ff0000',
              },
            }}
              startIcon={<CloseIcon />}
 
              onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
 
      <Dialog open={showCategoryDialog} onClose={() => setShowCategoryDialog(false)}>
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            fullWidth
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
         
          <Button
            sx={{
              backgroundColor: '#009999',
              color: '#fff',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#01dcb8',
              },
            }}
            onClick={async () => {
              await handleAddCategory();
              dispatch({
                type: 'SET_FORM_VALUES',
                payload: { category: newCategory },
              });
              setShowCategoryDialog(false);
            }}
            startIcon={<AddIcon />}
 
          >
            Add
          </Button>
          <Button variant="contained"
            color="error"
            onClick={() => setShowCategoryDialog(false)}
            startIcon={<CloseIcon />}
 
          >Cancel</Button>
        </DialogActions>
      </Dialog>
 
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setErrorSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
          Please fill in all fields correctly.
        </Alert>
      </Snackbar>
 
      {alertInfo.open && (
        <Alert
          severity={alertInfo.severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setAlertInfo({ ...alertInfo, open: false })}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          icon={alertInfo.severity === 'success' ? <CheckCircleOutlineIcon /> : <WarningIcon />}
          sx={{
            position: 'fixed',
            top: 16,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 9999,
          }}
        >
          {alertInfo.message}
        </Alert>
      )}
    </Dialog>
  );
};
 
export default DialogProduct;