import React, { useState, useEffect, useCallback } from 'react';
import {
  Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton,
  Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Box, Grid, MenuItem,
  CircularProgress, FormControl, InputLabel, Select, Autocomplete, FormHelperText
} from '@mui/material';
import { MdSearch, MdLocalShipping, } from 'react-icons/md';
import Pagination from '@mui/lab/Pagination';
import { Alert } from '@mui/lab';
import styles from './inventory.module.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { ClearIcon } from '@mui/x-date-pickers';
import AddIcon from '@mui/icons-material/Add';

const apiurl = process.env.REACT_APP_API_URL;


const Inventory = () => {
  const [inventoryList, setInventoryList] = useState([]);
  // const [transferProducts, setTransferProducts] = useState([{ productId: '', pieces: '', amount: '' }]);

  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [query, setQuery] = useState('');
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const [fromStoreId, setFromStoreId] = useState('');
  const [toStoreId, setToStoreId] = useState('');
  const [transferProduct, setTransferProduct] = useState([{ id: '', inventoryPieces: '', displayPieces: '', displayFromDate: '', display: false }]);
  const [stores, setStores] = useState([]);
  const [productsData, setProductsData] = useState([]);

  const [userRole, setUserRole] = useState("");
  const [display, setDisplay] = useState(false);
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [fromStoreError, setFromStoreError] = useState(false);
  const [toStoreError, setToStoreError] = useState(false);


  const [storeIds, setStoreIds] = useState(''); // Changed from storeName to storeId
  const [storeSearch, setStoreSearch] = useState('');

  const [overallDisplay, setOverallDisplay] = useState(false);

  const getCurrentDate = () => {
    return new Date().toISOString().split('T')[0];
  };

  //================================================================================================
  const [order, setOrder] = useState(''); // New state for order by

  const handleOrderChange = (event) => {
    setOrder(event.target.value);
    setCurrentPage(1); // Reset to first page when changing order
  };

  const handleRemoveRow = (index) => {
    const updatedRows = transferProduct.filter((row, rowIndex) => rowIndex !== index);
    setTransferProduct(updatedRows);
  };


  const addProduct = () => {
    setTransferProduct([...transferProduct, { id: '', inventoryPieces: '', displayPieces: '', displayFromDate: getCurrentDate(), }]);
  };

  const commonTextFieldStyles = {
    '& .MuiOutlinedInput-root': {
      height: '40px',
      '& fieldset': {
        borderColor: '#888',
      },
      '&:hover fieldset': {
        borderColor: '#555',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#009999',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#555',
      '&.Mui-focused': {
        color: '#009999',
      },
    },
    '& .MuiInputBase-input': {
      color: '#333',
    },
  };


  const handleImageClick = (imageSrc) => {
    setEnlargedImage(imageSrc);
  };
  const handleCloseEnlargedImage = () => {
    setEnlargedImage(null);
  };

  const getInventoryColor = (quantity) => {
    if (quantity > 20) return { background: '#eedf5d', text: 'black' }; // Yellow background, black text
    if (quantity > 3 && quantity <= 20) return { background: '#6193ff', text: 'white' }; // Blue background, white text
    return { background: '#de5757', text: 'white' }; // Red background, white text
  };

  const tableCellStyle = {
    height: '25px',
    padding: '4px',
    textAlign: 'center',
    fontSize: '13px',
    border: '1px solid #333'
  };

  const LoadingOverlay = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex:9999
    }}>
      <CircularProgress />
    </div>
  );

  //================================alert =====================================================================================
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });

  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
    setTimeout(() => setAlertInfo({ open: false, message: '', severity: 'success' }), 5000); // Close after 5 seconds
  };

  const [totalPages, setTotalPages] = useState(1);

  const handleInputChange = (index, field, value) => {

    if (field === 'storeId') {
      setFromStoreId(value);
    } else {
      const newProducts = [...transferProduct];

      if (field === 'display') {
        // Update the display state and product's display field
        setDisplay(value);
        newProducts[index][field] = value;
      } else if (['inventoryPieces', 'displayPieces'].includes(field)) {
        // Validation for numeric input fields
        if (!/^\d*\.?\d*$/.test(value)) {
          return;
        }
        newProducts[index][field] = value;
      } else if (field === 'id') {
        // Handling the selection of product by id
        const selectedProduct = productsData.find(p => p.id === value);
        if (selectedProduct) {
          newProducts[index] = {
            ...newProducts[index],
            id: selectedProduct.id,
            displayFromDate: newProducts[index].displayFromDate || getCurrentDate(),

          };
        }
      } else {
        newProducts[index][field] = value;
      }

      setTransferProduct(newProducts);
    }
  };  //========================================fetch Inventory===========================================================
  const fetchInventory = useCallback(async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      let url = new URL(`${apiurl}inventory/details`);
      url.searchParams.append('page', currentPage);
      url.searchParams.append('limit', rowsPerPage);
      url.searchParams.append('order', order); // Add orderBy to API call

      if (fromDate) url.searchParams.append('fromDate', fromDate);
      if (toDate) url.searchParams.append('toDate', toDate);
      if (query) url.searchParams.append('query', query);
      if (storeIds) url.searchParams.append('storeIds', storeIds);


      const response = await fetch(url.toString(), {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch inventory');
      }
      setInventoryList(data.product);
      setTotalPages(data.totalPages || 1);
    } catch (error) {
      showAlert(error.message, 'error');
      console.error('Fetch Error:', error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, showAlert]);


  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleStoreChange = (event, newValue) => {
    setStoreIds(newValue ? newValue.id : '');
  };

  const handleStoreInputChange = (event, newInputValue) => {
    setStoreSearch(newInputValue);
  };

  const handleSearch = () => {
    fetchInventory();
    // setQuery('');
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchInventory();
  }, [currentPage, rowsPerPage]); // Remove fromDate and toDate from here


  const handleTransportInventory = () => {
    if (userRole.toLowerCase() !== 'admin' && userRole.toLowerCase() !== 'super-admin' && userRole.toLowerCase() !== 'store') {
      showAlert('You do not have permission to transfer inventory.', 'error');
      return;
    }
    setTransferDialogOpen(true);
  };

  const handleTransferDialogClose = () => {
    setTransferDialogOpen(false);
    setToStoreId('');
    setOverallDisplay(false);
    setTransferProduct([{ id: '', inventoryPieces: '', displayPieces: '', displayFromDate: getCurrentDate(), }]);
     // Only reset fromStoreId if the user is not a store user
  if (userRole.toLowerCase() !== 'store') {
    setFromStoreId('');
  }
};
  

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };


  //==============================================transfer api=======================================================
  const handleTransferSubmit = async (event) => {

    // Reset error states
    setFromStoreError(false);
    setToStoreError(false);

    // Validate fromStoreId and toStoreId
    if (userRole.toLowerCase() !== 'store' && !fromStoreId) {
      setFromStoreError(true);
      showAlert('From Store is required', 'error');
      return;
    }
    if (!toStoreId) {
      setToStoreError(true);
      showAlert('To Store is required', 'error');
      return;
    }

    // Validate products
    for (let i = 0; i < transferProduct.length; i++) {
      const product = transferProduct[i];
      if (!product.id) {
        showAlert(`Product is required`, 'error');
        return;
      }
      if (!product.inventoryPieces || product.inventoryPieces <= 0) {
        showAlert(`Pieces are required`, 'error');
        return;
      }

    }

    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiurl}inventory`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          fromStoreId: parseInt(fromStoreId),
          toStoreId: parseInt(toStoreId),
          display: overallDisplay,
          products: transferProduct.map(product => ({
            id: parseInt(product.id),
            inventoryPieces: parseInt(product.inventoryPieces),
            displayPieces: parseInt(product.displayPieces),
            storeLocation: String(product.storeLocation),
            displayFromDate: String(product.displayFromDate),
          })),
        })
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to transfer inventory');
      }
      showAlert('Transfer successful', 'success');

      handleTransferDialogClose();
      // Refresh the inventory list after transfer
      handleSearch();
    } catch (error) {
      showAlert(error.message, 'error');
    } finally {
      setLoading(false);
    }

  };

  const fetchStores = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiurl}store`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch stores');
      }
      const data = await response.json();
      if (data.status === 'success' && Array.isArray(data.stores)) {
        const storeList = data.stores.map(store => ({
          id: store.storeId,
          storeName: store.storeName,
        }));
        setStores(storeList);
      } else {
        console.error('Unexpected data format:', data);
        setStores([]);
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
      setStores([]);
    }
  };

  const fetchProducts = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiurl}product/list`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      if (data.status === 'success' && Array.isArray(data.products)) {
        const productList = data.products.map(product => ({
          id: product.productId,
          productName: product.productName,
          categoryName: product.categoryName,
        }));
        setProductsData(productList);
      } else {
        console.error('Unexpected data format:', data);
        setProductsData([]);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setProductsData([]);
    }
  };

  useEffect(() => {
    fetchStores();
    fetchProducts();
  }, []);
  //=================================================user api==============================================================
  const [userStore, setUserStore] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${apiurl}user/loggeduser`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setUserRole(data.user.roleName.toLowerCase());
        if (data.user.roleName.toLowerCase() === 'store') {
          setUserStore({
            id: data.user.storeId,
            storeName: data.user.storeName
          });
          setFromStoreId(data.user.storeId); // Set the storeId for store users
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const storedRole = localStorage.getItem("roleName");
    setUserRole(storedRole || "");
  }, []);


  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className={styles.container}>
      <div className={styles.maincontainer}>
        {loading && <LoadingOverlay />}
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              name="fromDate"
              label="From Date"
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="toDate"
              label="To Date"
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="query"
              label="Search by Product/Category Name"
              value={query}
              onChange={handleQueryChange}
              InputProps={{
                className: styles.invoiceinput,
              }}
              InputLabelProps={{
                className: styles.invoicelabel,
                shrink: true
              }}
            />
          </Grid>


          <FormControl size='small' sx={{ margin: 1, minWidth: 200, height: 40, marginTop: 2 }}>
            <Autocomplete
              options={stores}
              getOptionLabel={(option) => option.storeName}
              value={stores.find(store => store.id === storeIds) || null}
              onChange={handleStoreChange}
              onInputChange={handleStoreInputChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Store"
                  variant="outlined"
                  size="small"
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.storeName}
                </li>
              )}
            />
          </FormControl>


          <Grid item>
            <Button
              variant="contained"
              onClick={handleSearch}
              sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
              startIcon={<MdSearch />}
            >
              Search
            </Button>
          </Grid>

          <Grid item >
            {(userRole.toLowerCase() === 'admin' || userRole.toLowerCase() === 'super-admin' || userRole.toLowerCase() === 'store') && (
              <Button
                variant="contained"
                startIcon={<MdLocalShipping />}
                onClick={handleTransportInventory}
                sx={{
                  backgroundColor: '#009999',
                  color: '#fff',
                  textTransform: 'capitalize',
                  '&:hover': {
                    backgroundColor: '#01dcb8',
                  },
                }}
              >
                Transfer
              </Button>
            )}
          </Grid>
        </Grid>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px', gap: "15px" }}>

          <FormControl variant="outlined" size="small" sx={{ minWidth: 120, maxHeight: 5, marginBottom: 5, marginTop: 3 }}>
            <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
            <Select
              labelId="rows-per-page-label"
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              label="Rows per page"
            >
              {[10, 25, 50, 100].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" size="small" sx={{ minWidth: 120, maxHeight: 5, marginTop: 3, marginBottom: 5, }}>
            <InputLabel id="order-by-label">Order By</InputLabel>
            <Select
              labelId="order-by-label"
              value={order}
              onChange={handleOrderChange}
              label="Order By"
            >
              <MenuItem value=""><em>None</em></MenuItem>
              <MenuItem value="asc">Low to high</MenuItem>
              <MenuItem value="desc">High to low</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className={styles.tablecontainer}>
          <TableContainer component={Paper}>
            <Table className={styles.storetable}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Sr No.</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Product Name</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Category Name</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Image</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Total Purchase Pcs</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Total sold Pcs</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Transfer pcs</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>
                    Total Available Pcs
                    <IconButton
                      size="small"
                      onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                      sx={{ color: '#fff' }}
                    >
                      {order === 'asc' ? '▲' : '▼'}
                    </IconButton>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Store Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inventoryList.map((product, index) => {

                  return (
                    <TableRow
                      key={product._id || index}
                      style={{ backgroundColor: getInventoryColor(product.availableQuantity).background }}
                    >
                      <TableCell style={{ ...tableCellStyle, color: getInventoryColor(product.availableQuantity).text }}>
                        {(index + 1 + (currentPage - 1) * itemsPerPage).toString()}
                      </TableCell>
                      <TableCell style={{ ...tableCellStyle, color: getInventoryColor(product.availableQuantity).text }}>
                        {product.productName}
                      </TableCell>
                      <TableCell style={{ ...tableCellStyle, color: getInventoryColor(product.availableQuantity).text }}>
                        {product.categoryName}
                      </TableCell>
                      <TableCell style={{ ...tableCellStyle, color: getInventoryColor(product.availableQuantity).text }}>
                        <img
                          src={product.image}
                          alt={product.productName}
                          style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                          onClick={() => handleImageClick(product.image)}
                        />
                      </TableCell>
                      <TableCell style={{ ...tableCellStyle, color: getInventoryColor(product.availableQuantity).text }}>
                        {product.totalReceived}
                      </TableCell>
                      <TableCell style={{ ...tableCellStyle, color: getInventoryColor(product.availableQuantity).text }}>
                        {product.totalSold}
                      </TableCell>
                      <TableCell style={{ ...tableCellStyle, color: getInventoryColor(product.availableQuantity).text }}>
                        {product.totalOut}
                      </TableCell>
                      <TableCell style={{ ...tableCellStyle, color: getInventoryColor(product.availableQuantity).text }}>
                        {product.availableQuantity}
                      </TableCell>
                      <TableCell style={{ ...tableCellStyle, color: getInventoryColor(product.availableQuantity).text }}>
                        {product.storeName}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* ======================================image inlarge=========================================================================================== */}

          <Dialog open={enlargedImage !== null} onClose={handleCloseEnlargedImage}>
            <DialogContent sx={{ padding: 0 }}>
              <img src={enlargedImage} alt="Enlarged product" style={{ width: '100%', height: 'auto' }} />
            </DialogContent>
          </Dialog>

          {/* ====================================================alert======================================================= */}


          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px', }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#009999',
                  border: '1px solid #ddd',
                  margin: '0 4px',
                  borderRadius: '4px',
                },
                '& .Mui-selected': {
                  backgroundColor: '#009999',
                  color: 'white',
                },
              }}
            />
          </Box>
        </div>

        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setErrorSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
            {error}
          </Alert>
        </Snackbar>

        <Dialog
          open={transferDialogOpen}
          onClose={() => { }}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              maxWidth: 'auto',
              maxHeight: 'auto',
              margin: 'auto',
              mt: 0, // Set the margin-top to 0
            },
          }}
          sx={{
            display: 'flex',
            alignItems: 'flex-start', // Align dialog to the top
            justifyContent: 'center',
          }}

        >
          <DialogTitle>Transfer Products</DialogTitle>
          <DialogContent>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleTransferSubmit}
              sx={{ mt: 2 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth error={fromStoreError}>
                    <InputLabel
                      sx={{
                        top: '-6px',
                        color: '#555',
                        '&.Mui-focused': { color: '#009999' },
                      }}
                    >
                      From Store <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <Select
                      name="fromStoreId"
                      required
                      value={fromStoreId}
                      onChange={(e) => {
                        setFromStoreId(e.target.value);
                        setFromStoreError(false); // Clear error when a selection is made
                      }}
                      label="From Store"
                      sx={{
                        height: '40px',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: fromStoreError ? 'red' : '#888',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#555',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#009999',
                        },
                      }}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                      {stores.map((store) => (
                        <MenuItem key={store.id} value={store.id}>
                          {store.storeName}
                        </MenuItem>
                      ))}
                    </Select>
                    {fromStoreError && <FormHelperText>From Store is required</FormHelperText>}
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth error={toStoreError}>
                    <InputLabel
                      sx={{
                        top: '-6px',
                        color: '#555',
                        '&.Mui-focused': { color: '#009999' },
                      }}
                    >
                      To Store <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <Select
                      name="toStoreId"
                      required
                      value={toStoreId}
                      onChange={(e) => {
                        setToStoreId(e.target.value);
                        setToStoreError(false); // Clear error when a selection is made
                      }}
                      label="To Store"
                      sx={{
                        height: '40px',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: toStoreError ? 'red' : '#888',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#555',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#009999',
                        },
                      }}
                    >
                      <MenuItem value=""><em>None</em></MenuItem>
                      {stores.map((store) => (
                        <MenuItem key={store.id} value={store.id}>
                          {store.storeName}
                        </MenuItem>
                      ))}
                    </Select>
                    {toStoreError && <FormHelperText>To Store is required</FormHelperText>}
                  </FormControl>
                </Grid>

                <table className={styles.invoicetabledata}>
                  <thead>
                    <tr>
                      <th className={styles.invoiceTh}>Sr_No</th>
                      <th className={styles.invoiceTh}>Select Product</th>
                      <th className={styles.invoiceTh}>Pieces</th>
                      <th className={styles.invoiceTh}>
                        <input
                          type="checkbox"
                          checked={overallDisplay}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            setOverallDisplay(checked);
                            const updatedProducts = transferProduct.map((product) => ({
                              ...product,
                              display: checked,
                            }));
                            setTransferProduct(updatedProducts);
                            console.log('Overall Display:', checked);
                          }}
                        /> Transfer
                      </th> {/* Select All checkbox */}
                      <th className={styles.invoiceTh}>Display pieces</th>
                      {/* <th className={styles.invoiceTh}>Store Location</th> */}
                      <th className={styles.invoiceTh}>Display From Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transferProduct.map((product, index) => {
                      const selectedProductIds = transferProduct.map(p => p.id);
                      return (
                        <tr key={index}>
                          <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ddd' }}>
                            {index + 1}
                          </td>
                          <td className={styles.invoiceTd}>
                            <Autocomplete
                              options={productsData.filter(p => !selectedProductIds.includes(p.id) || p.id === product.id)} // Exclude already selected products
                              getOptionLabel={(option) => option.productName || ''}
                              value={productsData.find(p => p.id === product.id) || null}
                              onChange={(event, newValue) => {
                                handleInputChange(index, 'id', newValue ? newValue.id : '');
                              }}
                              renderInput={(params) => (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <TextField
                                    {...params}

                                    variant="outlined"
                                    placeholder="Type to search..."
                                    sx={{
                                      width: '220px',
                                      '& .MuiOutlinedInput-root': {
                                        height: "30px",
                                        borderRadius: '0',
                                        '& fieldset': {
                                          borderColor: '#888',
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: '#333',
                                        },
                                      },
                                      '& .MuiInputBase-input': {
                                        width: "100%",
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: fromStoreError ? 'red' : '#888',
                                      },
                                      '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#555',
                                      },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#009999',
                                      },

                                    }}
                                  />
                                </div>
                              )}
                              filterOptions={(options, { inputValue }) =>
                                options.filter(option =>
                                  option.productName.toLowerCase().startsWith(inputValue.toLowerCase())
                                )
                              }
                            />

                          </td>
                          <td className={styles.invoiceTd}>
                            <input
                              value={product.inventoryPieces}
                              onChange={(e) => handleInputChange(index, 'inventoryPieces', e.target.value)}
                              className={styles.invoiceinputs}
                            />
                          </td>
                          <td className={styles.invoiceTd} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                            <input
                              type="checkbox"
                              checked={product.display}
                              onChange={(e) => {
                                const checked = e.target.checked;
                                handleInputChange(index, 'display', checked);
                                // Update overall display if all products are checked or unchecked
                                const allChecked = transferProduct.every(p => p.display);
                                setOverallDisplay(allChecked);
                              }}
                            />
                          </td>
                          <td className={styles.invoiceTd}>
                            {product.display && (
                              <input
                                value={product.displayPieces}
                                onChange={(e) => handleInputChange(index, 'displayPieces', e.target.value)}
                                className={styles.invoiceinputs}
                              />
                            )}
                          </td>
                          {/* <td className={styles.invoiceTd}>
                            {product.display && (
                              <input
                                type="text"
                                value={product.storeLocation || ''}
                                onChange={(e) => handleInputChange(index, 'storeLocation', e.target.value)}
                                className={styles.invoiceinputs}
                              />
                            )}
                          </td> */}
                          <td className={styles.invoiceTd}>
                            {product.display && (
                              <input
                                type="date"
                                value={product.displayFromDate || getCurrentDate()}
                                onChange={(e) => handleInputChange(index, 'displayFromDate', e.target.value)}
                                className={styles.invoiceinputs}
                              />
                            )}
                          </td>
                          <td className={styles.invoiceTd}>
                            <IconButton onClick={() => handleRemoveRow(index)} className={styles.deleteButton}>
                              <ClearIcon className={styles.invoiceicons} />
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>


                <Grid item xs={12}>
                  <Button onClick={addProduct} sx={{
                    backgroundColor: '#009999',
                    color: '#fff',
                    textTransform: 'capitalize',
                    '&:hover': {
                      backgroundColor: '#01dcb8',
                    },
                  }}
                    variant="outlined"
                    startIcon={<AddIcon />}

                  >
                    Add Product
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>

            <Button onClick={handleTransferSubmit} variant="contained" sx={{
              backgroundColor: '#009999',
              color: '#fff',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#01dcb8',
              },
            }}
              startIcon={<MdLocalShipping />}

            >
              Transfer
            </Button>

            <Button onClick={handleTransferDialogClose} variant="contained" color="error"
              startIcon={<CloseIcon />}

            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* ====================================================alert======================================================= */}

        {alertInfo.open && (
          <Alert
            severity={alertInfo.severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setAlertInfo({ ...alertInfo, open: false })}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            icon={alertInfo.severity === 'success' ? <CheckCircleOutlineIcon /> : <WarningIcon />}
            sx={{
              position: 'fixed',
              top: 16,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 9999,
            }}
          >
            {alertInfo.message}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default Inventory;