import React, { useState, useEffect, useCallback } from 'react';
import {
  Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton,
  Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Box, Grid, MenuItem,
  CircularProgress, FormControl, InputLabel, Select, Autocomplete
} from '@mui/material';
import { Add as AddIcon, } from '@mui/icons-material';
import { MdSearch, } from 'react-icons/md';
import Pagination from '@mui/lab/Pagination';
import { Alert } from '@mui/lab';
import styles from './display.module.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { ClearIcon } from '@mui/x-date-pickers';
import { FaDisplay } from "react-icons/fa6";

const apiurl = process.env.REACT_APP_API_URL;

const Display = () => {
  const [inventoryList, setInventoryList] = useState([]);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [search, setSearch] = useState('');
  const [storeName, setStoreName] = useState('');
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const [storeId, setStoreId] = useState('');
  const [transferProduct, setTransferProduct] = useState([{ id: '', storeLocation: '', displayFromDate: '', pieces: '' }]);
  const [stores, setStores] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [storeIdError, setStoreIdError] = useState('');
  const [productErrors, setProductErrors] = useState([]);

  const [userRole, setUserRole] = useState('');
  const [userStore, setUserStore] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${apiurl}user/loggeduser`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setUserRole(data.user.roleName.toLowerCase());
        if (data.user.roleName.toLowerCase() === 'store') {
          setUserStore({
            id: data.user.storeId,
            storeName: data.user.storeName
          });
          setStoreId(data.user.storeId); // Set the storeId for store users
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);


  const handleAddRow = () => {
    setTransferProduct([...transferProduct, { id: '', storeLocation: '', displayFromDate: '', pieces: '' }]);
  };

  const getCurrentDate = () => {
    return new Date().toISOString().split('T')[0];
  };

  const handleRemoveRow = (index) => {
    const updatedRows = transferProduct.filter((row, rowIndex) => rowIndex !== index);
    setTransferProduct(updatedRows);
  };

 

  const handleInputChange = (index, field, value) => {
    if (['rate', 'pieces', 'unit'].includes(field)) {
      if (!/^\d*\.?\d*$/.test(value)) {
        return;
      }
    }

    if (field === 'storeId') {
      setStoreId(value);
    } else {
      const newProducts = [...transferProduct];

      if (field === 'id') {
        const selectedProduct = productsData.find(p => p.id === value);
        if (selectedProduct) {
          newProducts[index] = {
            ...newProducts[index],
            id: selectedProduct.id,
            displayFromDate: newProducts[index].displayFromDate || getCurrentDate(),
          };
        }
      } else {
        newProducts[index][field] = value;
      }

      setTransferProduct(newProducts);
    }
  };

  useEffect(() => {
    const storedRole = localStorage.getItem("roleName");
    setUserRole(storedRole || "");
  }, []);

  const commonTextFieldStyles = {
    '& .MuiOutlinedInput-root': {
      height: '40px',
      '& fieldset': {
        borderColor: '#888',
      },
      '&:hover fieldset': {
        borderColor: '#555',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#009999',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#555',
      '&.Mui-focused': {
        color: '#009999',
      },
    },
    '& .MuiInputBase-input': {
      color: '#333',
    },
  };

  const handleImageClick = (imageSrc) => {
    setEnlargedImage(imageSrc);
  };
  const handleCloseEnlargedImage = () => {
    setEnlargedImage(null);
  };



  const tableCellStyle = {
    height: '25px',
    padding: '4px',
    textAlign: 'center',
    fontSize: '13px',
    border: '1px solid #333'
  };

  const LoadingOverlay = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex:9999
    }}>
      <CircularProgress />
    </div>
  );

  //================================alert =====================================================================================
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });

  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
    setTimeout(() => setAlertInfo({ open: false, message: '', severity: 'success' }), 5000); // Close after 5 seconds
  };


  const [totalPages, setTotalPages] = useState(1);

  const fetchInventory = useCallback(async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      let url = new URL(`${apiurl}display`);
      url.searchParams.append('page', currentPage);
      url.searchParams.append('limit', rowsPerPage);
      if (fromDate) url.searchParams.append('fromDate', fromDate);
      if (toDate) url.searchParams.append('toDate', toDate);
      if (search) url.searchParams.append('search', search);

      const response = await fetch(url.toString(), {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch inventory');
      }
      setInventoryList(data.data);
      setTotalPages(data.totalPages || 1);
    } catch (error) {
      showAlert(error.message, 'error');
      console.error('Fetch Error:', error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, fromDate, toDate, rowsPerPage, search, showAlert]);


  const handleQueryChange = (e) => {
    setSearch(e.target.value);
  };
  const handleStoreChange = (e) => {
    setStoreName(e.target.value);
  };

  const handleSearch = () => {
    fetchInventory();
    // setSearch('');
  };
  const handleStoreSearch = () => {
    fetchInventory();
    setStoreName('');
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to the first page whenever rows per page is changed
  };

  const handlePageChange = (event, newPage) => {
    if (!isNaN(newPage)) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    fetchInventory();
  }, [currentPage, fromDate, toDate, rowsPerPage]); // Remove fromDate and toDate from here


  const handleTransportInventory = () => {
    if (userRole.toLowerCase() !== 'admin' && userRole.toLowerCase() !== 'super-admin' && userRole.toLowerCase() !== 'store') {
      showAlert('You do not have permission to transfer inventory.', 'error');
      return;
    }
    setTransferDialogOpen(true);
  };

  const handleTransferDialogClose = () => {
    setTransferDialogOpen(false);
    setStoreId('');
    setTransferProduct([{ id: '', storeLocation: '', displayFromDate: getCurrentDate(), pieces: '' }]);
  };


  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };


  //==============================================transfer api=======================================================
  const handleTransferSubmit = async () => {
   

    // Reset error states
    setStoreIdError(false);

  
    if (!storeId) {
      setStoreIdError(true);
      showAlert('Store is required', 'error');
      return;
    }
   
    // Validate products
    for (let i = 0; i < transferProduct.length; i++) {
      const product = transferProduct[i];
      if (!product.id) {
        showAlert(`Product is required`, 'error');
        return;
      }
      if (!product.pieces || product.pieces <= 0) {
        showAlert(`Pieces are required`, 'error');
        return;
      }
    
    }

    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiurl}display`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          storeId: parseInt(storeId),
          products: transferProduct.map(product => ({
            id: parseInt(product.id),
            storeLocation: String(product.storeLocation),
            displayFromDate: String(product.displayFromDate),
            pieces: parseInt(product.pieces),
          })),
        })
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to transfer inventory');
      }
      showAlert('Transfer successful', 'success');

      handleTransferDialogClose();
      // Refresh the inventory list after transfer
      handleSearch();
    } catch (error) {
      showAlert(error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchStores = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiurl}store`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch stores');
      }
      const data = await response.json();
      if (data.status === 'success' && Array.isArray(data.stores)) {
        const storeList = data.stores.map(store => ({
          id: store.storeId,
          storeName: store.storeName,
        }));
        setStores(storeList);
      } else {
        console.error('Unexpected data format:', data);
        setStores([]);
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
      setStores([]);
    }
  };

  const fetchProducts = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiurl}product/list`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      if (data.status === 'success' && Array.isArray(data.products)) {
        const productList = data.products.map(product => ({
          id: product.productId,
          productName: product.productName,
        }));
        setProductsData(productList);
      } else {
        console.error('Unexpected data format:', data);
        setProductsData([]);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setProductsData([]);
    }
  };

  useEffect(() => {
    fetchStores();
    fetchProducts();
  }, []);

  //-===================================userRole=======================================================


  useEffect(() => {
    const storedRole = localStorage.getItem("roleName");
    setUserRole(storedRole || "");
  }, []);

  const hasPermission = (allowedRoles) => {
    const userRole = localStorage.getItem("roleName")?.toLowerCase();
    return allowedRoles.includes(userRole);

  };//=====================================================================================================

  return (
    <div className={styles.container}>
      <div className={styles.mainContainer} >
        {loading && <LoadingOverlay />}
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              name="fromDate"
              label="From Date"
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="toDate"
              label="To Date"
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="search"
              label="Search by Product Name"
              value={search}
              onChange={handleQueryChange}
              InputProps={{
                className: styles.invoiceinput,
              }}
              InputLabelProps={{
                className: styles.invoicelabel,
                shrink: true
              }}
            />
          </Grid>
          <Grid item>
            <Button
              sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
              variant="contained"
              onClick={handleSearch}
              startIcon={<MdSearch />}
            >
              Search
            </Button>
          </Grid>
          <Grid item >
            {hasPermission(['admin', 'super-admin', 'store']) && (

              <Button
                sx={{
                  backgroundColor: '#009999',
                  color: '#fff',
                  textTransform: 'capitalize',
                  '&:hover': {
                    backgroundColor: '#01dcb8',
                  },
                }}
                variant="contained"
                startIcon={<FaDisplay />}
                onClick={handleTransportInventory}
              >
                Display
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 120, maxHeight: 5, marginBottom: 5, marginTop: 3 }}>
            <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
            <Select
              labelId="rows-per-page-label"
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              label="Rows per page"
            >
              {[10, 25, 50, 100].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <div className={styles.tablecon}>
          <TableContainer component={Paper}>
            <Table className={styles.storetable}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Sr No.</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Product Name</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Image</TableCell>
                  {/* <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Store Location</TableCell> */}
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Display From Date</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Display pcs</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inventoryList.map((product, index) => (
                  <TableRow key={product._id} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f0f0f0' }}>
                    <TableCell style={tableCellStyle}>
                      {(Number(index || 0) + 1 + (Number(currentPage || 1) - 1) * Number(itemsPerPage || 10))
                        .toString()
                        .padStart(4, '0')}
                    </TableCell>
                    <TableCell style={tableCellStyle}>{product.productName}</TableCell>
                    <TableCell style={tableCellStyle}>
                      <img
                        src={product.image}
                        alt={product.productName}
                        style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                        onClick={() => handleImageClick(product.image)}
                      />
                    </TableCell>
                    {/* <TableCell style={tableCellStyle}>{product.storeLocation}</TableCell> */}
                    <TableCell style={tableCellStyle}>{product.displayFromDate}</TableCell>
                    <TableCell style={tableCellStyle}>{product.pieces}</TableCell>
                    {/* <TableCell style={tableCellStyle}>
                    <IconButton onClick={handleEdit}> <ModeEditOutline className={styles.editIcon}/> </IconButton>
                    <IconButton onClick={handleDelete}> <DeleteIcon className={styles.editDelete}/> </IconButton>
                    </TableCell> */}
                    <TableCell style={tableCellStyle}>{product.createdAt}</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>

            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#009999',
                  border: '1px solid #ddd',
                  margin: '0 4px',
                  borderRadius: '4px',
                },
                '& .Mui-selected': {
                  backgroundColor: '#009999',
                  color: 'white',
                },
              }}
            />
          </Box>
        </div>
        {/* ======================================image inlarge=========================================================================================== */}

        <Dialog open={enlargedImage !== null}
          onClose={handleCloseEnlargedImage}>
          <DialogContent sx={{ padding: 0 }}>
            <img src={enlargedImage} alt="Enlarged product" style={{ width: '100%', height: 'auto' }} />
          </DialogContent>
        </Dialog>

        {/* ====================================================alert======================================================= */}

        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setErrorSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
            {error}
          </Alert>
        </Snackbar>

        <Dialog
          open={transferDialogOpen}
          onClose={handleTransferDialogClose}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              maxWidth: 'auto',
              maxHeight: 'auto',
              margin: 'auto',
              mt: 0, // Set the margin-top to 0
            },
          }}
          sx={{
            display: 'flex',
            alignItems: 'flex-start', // Align dialog to the top
            justifyContent: 'center',
          }}
        >
          <DialogTitle>Transfer Products</DialogTitle>
          <DialogContent>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleTransferSubmit}
              sx={{ mt: 2 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl className={styles.stores}>
                    <InputLabel sx={{ top: '0px', color: '#555', '&.Mui-focused': { color: '#009999' } }}>Store <span style={{ color: 'red' }}>*</span></InputLabel>
                    <Select
                      name="storeId"
                      value={userRole === 'store' ? userStore?.id : storeId}
                      onChange={(e) => handleInputChange(0, 'storeId', e.target.value)}
                      required={userRole !== 'store'}
                      className={styles.textField}
                      label="Store"
                      sx={{
                        height: '30px',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#888',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#555',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#009999',
                        },
                      }}
                      disabled={userRole === 'store'}
                    >
                      {userRole === 'store' ? (
                        <MenuItem value={userStore?.id}>{userStore?.storeName}</MenuItem>
                      ) : (
                        [
                          <MenuItem key="none" value=""><em>None</em></MenuItem>,
                          ...stores.map((store) => (
                            <MenuItem key={store.id} value={store.id}>
                              {store.storeName}
                            </MenuItem>
                          ))
                        ]
                      )}
                    </Select>
                    {storeIdError && <span style={{ color: 'red', fontSize: '0.75rem' }}>{storeIdError}</span>}
                  </FormControl>
                </Grid>

                <div>
                  <table className={styles.invoicetabledata}>
                    <thead>
                      <tr>
                        <th className={styles.invoiceTh}>Sr_No</th>
                        <th className={styles.invoiceTh}>Select Product</th>
                        <th className={styles.invoiceTh}>Store Location</th>
                        <th className={styles.invoiceTh}>Display From Date</th>
                        <th className={styles.invoiceTh}>Pieces</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transferProduct.map((product, index) => {
                        const selectedProductIds = transferProduct.map(p => p.id);
                        return (
                          <tr key={index}>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ddd' }}>
                              {index + 1}
                            </td>
                            <td className={styles.invoiceTd}>
                              <Autocomplete
                                options={productsData.filter(p => !selectedProductIds.includes(p.id) || p.id === product.id)} // Exclude already selected products
                                getOptionLabel={(option) => option.productName || ''}
                                value={productsData.find(p => p.id === product.id) || null}
                                onChange={(event, newValue) => {
                                  handleInputChange(index, 'id', newValue ? newValue.id : '');
                                }}
                                renderInput={(params) => (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      placeholder="Type to search..."
                                      sx={{
                                        width: '220px', // Adjust this value as needed
                                        '& .MuiOutlinedInput-root': {
                                          height: "30px",
                                          borderRadius: '0',
                                          '& fieldset': {
                                            borderColor: '#888',
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderColor: '#333',
                                          },
                                        },
                                        '& .MuiInputBase-input': {
                                          width: "100%",
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                                filterOptions={(options, { inputValue }) =>
                                  options.filter(option =>
                                    option.productName.toLowerCase().startsWith(inputValue.toLowerCase())
                                  )
                                }
                              />
                            </td>
                            <td className={styles.invoiceTd}>
                              <input
                                type="text"
                                value={product.storeLocation || ''}
                                onChange={(e) => handleInputChange(index, 'storeLocation', e.target.value)}
                                className={styles.invoiceinputs}
                              />
                            </td>
                            <td className={styles.invoiceTd}>
                              <input
                                type="date"
                                value={product.displayFromDate || getCurrentDate()}
                                onChange={(e) => handleInputChange(index, 'displayFromDate', e.target.value)}
                                className={styles.invoiceinputs}
                                style={{ textAlign: 'right' }}
                              />
                            </td>

                            <td className={styles.invoiceTd}>
                              <input
                                value={product.pieces}
                                onChange={(e) => handleInputChange(index, 'pieces', e.target.value)}
                                className={styles.invoiceinputs}
                              />
                            </td>
                            <td className={styles.invoiceTd}>
                              <IconButton onClick={() => handleRemoveRow(index)} className={styles.deleteButton}>
                                <ClearIcon className={styles.invoiceicons} />
                              </IconButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Button className={styles.buttons}
                    variant="contained"
                    sx={{
                      backgroundColor: '#009999',
                      color: '#fff',
                      textTransform: 'capitalize',
                      '&:hover': {
                        backgroundColor: '#01dcb8',
                      },
                    }}
                    startIcon={<AddIcon />}
                    onClick={handleAddRow}
                  >
                    Add Line
                  </Button>
                </div>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>

            <Button onClick={handleTransferSubmit} variant="contained" sx={{
              backgroundColor: '#009999',
              color: '#fff',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#01dcb8',
              },
            }}
              startIcon={<AddIcon />}

            >
              Submit
            </Button>

            <Button onClick={handleTransferDialogClose} variant="contained"
              sx={{
                backgroundColor: '#e32e2e',
                color: "fff",
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#ff0000',
                },
              }}
              startIcon={<CloseIcon />}

            >
              Cancel
            </Button>

          </DialogActions>
        </Dialog>
        {/* ====================================================alert======================================================= */}

        {alertInfo.open && (
          <Alert
            severity={alertInfo.severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setAlertInfo({ ...alertInfo, open: false })}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            icon={alertInfo.severity === 'success' ? <CheckCircleOutlineIcon /> : <WarningIcon />}
            sx={{
              position: 'fixed',
              top: 16,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 9999,
            }}
          >
            {alertInfo.message}
          </Alert>
        )}

      </div>
    </div>
  );
};

export default Display;