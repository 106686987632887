import React, { useReducer, useState, useEffect } from 'react';
import {
    Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar,
    Box, Grid, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { MdOutlineAddchart, MdSearch } from 'react-icons/md';
import Pagination from '@mui/lab/Pagination';
import { Alert } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { Delete, ModeEditOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import styles from '../customer/customer.module.css';
import Diversity3Icon from '@mui/icons-material/Diversity3';

const apiurl = process.env.REACT_APP_API_URL;
const initialState = {
    formValues: {
        customerName: '',
        contactNumber: '',
        address1: '',
        state: '',
        city: '',
        pinCode: '',
    },
    errors: {},
};

const formReducer = (state, action) => {
    switch (action.type) {
        case 'SET_FORM_VALUES':
            return {
                ...state,
                formValues: { ...state.formValues, ...action.payload },
            };
        case 'SET_ERRORS':
            return {
                ...state,
                errors: { ...state.errors, ...action.payload },
            };
        case 'RESET_FORM':
            return {
                ...state,
                formValues: initialState.formValues,
                errors: {},
            };
        default:
            return state;
    }
};

const Customer = () => {
    const [state, dispatch] = useReducer(formReducer, initialState);
    const [customerId, setcustomerId] = useState(null);
    const [customerList, setCustomerList] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [states, setStates] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const itemsPerPage = 10;


    //========================================================loading===============================================================
    const LoadingOverlay = () => (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex:9999
        }}>
            <CircularProgress />
        </div>
    );
    //================================alert =====================================================================================
    const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });

    const showAlert = (message, severity) => {
        setAlertInfo({ open: true, message, severity });
        setTimeout(() => setAlertInfo({ open: false, message: '', severity: 'success' }), 5000); // Close after 5 seconds
    };

    //====================================================================================

    const tableCellStyle = {
        height: '25px',
        padding: '4px',  // Ensure no extra padding affects height
        textAlign: 'center',
        fontSize: '13px',
        border: '1px solid #333'

    };

    const commonTextFieldStyles = {
        '& .MuiOutlinedInput-root': {
            height: '40px',
            '& fieldset': {
                borderColor: '#888',
            },
            '&:hover fieldset': {
                borderColor: '#555',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#009999',
            },
        },
        '& .MuiInputLabel-root': {
            color: '#555',
            '&.Mui-focused': {
                color: '#009999',
            },
        },
        '& .MuiInputBase-input': {
            color: '#333',
        },
    };

    const [customer, setCustomers] = useState([]);

    const fetchCustomers = async () => {
        const token = localStorage.getItem('token');
        setIsLoading(true);
        if (!token) {
            console.error('No token found');
            return;
        }
        try {
            let url = new URL(`${apiurl}customer`);

            url.searchParams.append('page', currentPage);
            url.searchParams.append('limit', rowsPerPage);

            if (fromDate) url.searchParams.append('fromDate', fromDate);
            if (toDate) url.searchParams.append('toDate', toDate);
            if (search) url.searchParams.append('search', search);

            const response = await fetch(url.toString(), {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch customers');
            }

            const data = await response.json();

            if (data.status === "success") {
                setCustomerList(data.data);
                setTotalPages(data.totalPages || Math.ceil(data.total / rowsPerPage));
                setCurrentPage(data.currentPage || 1);
            } else {
                throw new Error('Unexpected data format');
            }
        } catch (error) {
            console.error('Error fetching customers:', error);
            showAlert('Failed to fetch customers', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCustomers();
    }, [currentPage, rowsPerPage]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleSearch = () => {
        fetchCustomers();
        // setSearch('');

    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to first page when changing rows per page
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        fetchCustomers();
    }, [currentPage, rowsPerPage,]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (['amount'].includes(name)) {
            const isNumeric = /^\d*\.?\d*$/.test(value);
            if (!isNumeric) return;

        } else {
            dispatch({ type: 'SET_FORM_VALUES', payload: { [name]: value } });
        }
        dispatch({ type: 'SET_ERRORS', payload: { [name]: '' } });
    };


    const validateForm = () => {
        const { customerName, contactNumber, } = state.formValues;
        const newErrors = {};
        console.log(state.formValues)
        if (!customerName) newErrors.customerName = 'Customer Name is required';
        if (!contactNumber) newErrors.contactNumber = 'Number is required';


        dispatch({ type: 'SET_ERRORS', payload: newErrors });
        return Object.keys(newErrors).length === 0;
    };


    const handleAddorEditCustomer = async (e) => {
        const token = localStorage.getItem('token');

        setIsLoading(true);

        e.preventDefault();

        if (!validateForm()) {
            setErrorSnackbarOpen(true);
            setIsLoading(false);  // Set loading to false if validation fails

            return;
        }
        const newCustomer = {

            customerName: state.formValues.customerName,
            contactNumber: state.formValues.contactNumber,
            address1: state.formValues.address1,
            state: state.formValues.state,
            city: state.formValues.city,
            pinCode: state.formValues.pinCode,

        };

        try {
            let response; // Declare response variable
            if (editIndex !== null) {
                response = await fetch(`${apiurl}customer/${customerId}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },

                    body: JSON.stringify(newCustomer),
                });
            } else {
                response = await fetch(`${apiurl}customer`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },

                    body: JSON.stringify(newCustomer),
                });
            }
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'An error occurred while processing your request');
            }

            setCustomerList(prevList => {
                if (editIndex !== null) {
                    showAlert('Customer Details updated successfully', 'success');
                    return prevList.map((customer, index) =>
                        index === editIndex ? { ...customer, ...data } : customer
                    );
                } else {
                    showAlert('Customer Details added successfully', 'success');
                    return [data, ...prevList];
                }
            });

            dispatch({ type: 'RESET_FORM' });
            setShowForm(false);
            setEditIndex(null);
            setcustomerId(null);
            fetchCustomers();

        } catch (error) {
            console.error('Error adding/updating details:', error);
            showAlert(error.message || 'Failed to process customer details', 'error');
        } finally {
            setIsLoading(false);
        }
    };
  

    const handleConfirmDeleteOpen = (customerId) => {
        setDeleteIndex(customerId);
        setConfirmDeleteOpen(true);
    };

    const handleConfirmDeleteClose = () => {
        setConfirmDeleteOpen(false);
        setDeleteIndex(null);
    };

    const handleDeleteCustomer = async () => {
        const token = localStorage.getItem('token');
        setIsLoading(true);
        try {
            if (!deleteIndex) {
                throw new Error('Invalid customer selected for deletion');
            }

            const response = await fetch(`${apiurl}customer/${deleteIndex}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to delete customer');
            }

            showAlert('Customer deleted successfully', 'success');
            fetchCustomers();

        } catch (error) {
            console.error('Error deleting customer:', error);
            showAlert(`Failed to delete customer: ${error.message}`, 'error');
        } finally {
            setConfirmDeleteOpen(false);
            setDeleteIndex(null);
            setIsLoading(false);
        }
    };


    const handleEditCustomer = (_id, index) => {
        setcustomerId(_id);
        const actualIndex = (currentPage - 1) * itemsPerPage + index;
        const customerToEdit = customerList.find(product => product._id === _id);

        if (!customerToEdit) {
            console.error('customer not found:', _id, index, actualIndex);
            showAlert('Error: customer not found', 'error');
            return;
        }

        dispatch({
            type: 'SET_FORM_VALUES',
            payload: {
                customerName: customerToEdit.customerName || '',
                contactNumber: customerToEdit.contactNumber || '',
                address1: customerToEdit.address1 || '',
                state: customerToEdit.state || '',
                city: customerToEdit.city || '',
                pinCode: customerToEdit.pinCode || '',
            }
        });
        setShowForm(true);
        setEditIndex(customerList.findIndex(customer => customer._id === customerId));
    };

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    //states define api
    const fetchStates = async () => {
        const token = localStorage.getItem('token');

        try {
            // console.log('Fetching states...');
            const response = await fetch(`${apiurl}state`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });
            // console.log('Response status:', response.status);
            if (!response.ok) {
                throw new Error('Failed to fetch state');
            }
            const data = await response.json();
            // console.log('Fetched state data:', data);
            if (Array.isArray(data) && data.length > 0) {
                console.log('First state item:', data[0]);
            } else {
                console.log('Data is not an array or is empty');
            }
            setStates(data);
        } catch (error) {
            console.error('Error fetching state:', error);
        }
    };

    useEffect(() => {
        console.log('Calling fetchStates');
        fetchStates();
    }, []);


    return (
        <div className={styles.container}>
            <div className={styles.maincontainer}>
                {isLoading && <LoadingOverlay />}
                <Grid container spacing={2}>
                    <Grid item>
                        <TextField
                            name="fromDate"
                            label="From Date"
                            type="date"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            sx={{
                                ...commonTextFieldStyles,
                                width: '150px',
                            }}
                            InputLabelProps={{
                                className: styles.invoicelabel,
                                shrink: true // This ensures the label stays above the input value
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            name="toDate"
                            label="To Date"
                            type="date"
                            value={toDate}
                            onChange={handleToDateChange}
                            sx={{
                                ...commonTextFieldStyles,
                                width: '150px',
                            }}
                            InputLabelProps={{
                                className: styles.invoicelabel,
                                shrink: true // This ensures the label stays above the input value
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            name="search"
                            label="Search by Name/No"
                            value={search}
                            onChange={handleSearchChange}
                            InputProps={{
                                className: styles.searchinvoiceinput,
                            }}
                            InputLabelProps={{
                                className: styles.invoicelabel,
                                shrink: true
                            }} />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#009999',
                                color: '#fff',
                                textTransform: 'capitalize',
                                '&:hover': {
                                    backgroundColor: '#01dcb8',
                                },
                            }}
                            startIcon={<MdSearch />}
                            onClick={handleSearch}>
                            Search
                        </Button>
                    </Grid>
                    <Grid item >
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#009999',
                                color: '#fff',
                                textTransform: 'capitalize',
                                '&:hover': {
                                    backgroundColor: '#01dcb8',
                                },
                            }}
                            startIcon={<Diversity3Icon />}
                            onClick={() => setShowForm(true)}>
                            Add Customer
                        </Button>
                    </Grid>
                </Grid>

                <Dialog
                    open={showForm}
                    onClose={() => { }}
                    maxWidth="lg"
                    fullWidth
                    PaperProps={{
                        sx: {
                            margin: 'auto',
                            mt: 0, // Set the margin-top to 0
                            width: '100%'
                        },
                    }}
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start', // Align dialog to the top
                        justifyContent: 'center',
                        width: "100%"
                    }}
                >
                    <DialogTitle>{editIndex !== null ? 'Edit Customer' : 'Add Customer'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {editIndex !== null ? 'Edit the details of the Customer.' : 'Fill in the details of the new Customer.'}
                        </DialogContentText>
                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                            onSubmit={handleAddorEditCustomer}
                            sx={{ mt: 2, }}
                        >
                            <Grid container spacing={2}>

                                <Grid item xs={4}>
                                    <TextField
                                        name="customerName"
                                        label="Name"
                                        value={state.formValues.customerName}
                                        onChange={handleInputChange}
                                        InputProps={{ className: styles.invoiceinput }}
                                        error={!!state.errors.customerName}
                                        helperText={state.errors.customerName}
                                        required
                                        fullWidth
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '0px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                                // Hide the default asterisk and apply custom styles
                                                '& .MuiFormLabel-asterisk': {
                                                    color: 'red', // Customize the color of the asterisk
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000', // Text color
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        name="contactNumber"
                                        label="Contact Number"
                                        value={state.formValues.contactNumber}
                                        onChange={handleInputChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            pattern: "[0-9]*",
                                            inputMode: "numeric",
                                            maxLength: 10,
                                        }}
                                        error={!!state.errors.contactNumber}
                                        helperText={state.errors.contactNumber}
                                        InputProps={{ className: styles.invoiceinput }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '0px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                                // Hide the default asterisk and apply custom styles
                                                '& .MuiFormLabel-asterisk': {
                                                    color: 'red', // Customize the color of the asterisk
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000', // Text color
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        name="address1"
                                        label="Address"
                                        value={state.formValues.address1}
                                        onChange={handleInputChange}
                                        fullWidth
                                        InputProps={{ className: styles.invoiceinput }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '0px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth error={!!state.errors.state}>
                                        <InputLabel>State</InputLabel>
                                        <Select
                                            name="state"
                                            value={state.formValues.state}
                                            onChange={handleInputChange}
                                            label="State"
                                            className={styles.invoiceinput}
                                        >
                                            <MenuItem value=""><em>None</em></MenuItem>
                                            {Array.isArray(states) && states.map((stateItem) => (
                                                <MenuItem key={stateItem.id || stateItem._id} value={stateItem.state || stateItem.name}>
                                                    {stateItem.state || stateItem.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="city"
                                        label="City"
                                        value={state.formValues.city}
                                        onChange={handleInputChange}
                                        fullWidth
                                        InputProps={{ className: styles.invoiceinput }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '0px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="pinCode"
                                        label="Pincode"
                                        value={state.formValues.pinCode}
                                        onChange={handleInputChange}
                                        fullWidth
                                        inputProps={{
                                            pattern: "[0-9]{6}",
                                            inputMode: "numeric",
                                            maxLength: 6,
                                        }}
                                        InputProps={{ className: styles.invoiceinput }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '0px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000',
                                            },
                                        }}
                                    />
                                </Grid>

                            </Grid>
                            <DialogActions sx={{ mt: 2, justifyContent: "flex-start" }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#009999',
                                        color: '#fff',
                                        textTransform: 'capitalize',
                                        '&:hover': {
                                            backgroundColor: '#01dcb8',
                                        },
                                    }}
                                    type="submit"
                                    startIcon={<AddIcon />}

                                >
                                    {editIndex !== null ? 'Update Customer' : 'Add Customer'}
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#e32e2e',
                                        color: "fff",
                                        textTransform: 'capitalize',
                                        '&:hover': {
                                            backgroundColor: '#ff0000',
                                        },
                                    }}

                                    onClick={() => {
                                        dispatch({ type: 'RESET_FORM' });

                                        setShowForm(false);
                                        setEditIndex(null);
                                    }}
                                    startIcon={<CloseIcon />}

                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Box>
                    </DialogContent>
                </Dialog>

                <FormControl variant="outlined" size="small" sx={{ minWidth: 120, maxHeight: 5, marginBottom: 5, marginTop: 3 }}>
                    <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
                    <Select
                        labelId="rows-per-page-label"
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        label="Rows per page"
                    >
                        {[10, 25, 50, 100].map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <div className={styles.dailytablecontainer}>
                    <TableContainer component={Paper}>
                        <Table className={styles.storetable}>
                            <TableHead>
                                <TableRow>

                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Sr No.</TableCell>
                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Customer Name</TableCell>
                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Contact No</TableCell>
                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Address</TableCell>
                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>City</TableCell>
                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>State</TableCell>
                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Pincode</TableCell>
                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Date</TableCell>
                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {customerList?.map((customer, index) => (
                                    <TableRow key={customer._id}
                                        style={{
                                            backgroundColor: !customer.isActive
                                                ? '#ffcccc'  // Light red color for inactive rows
                                                : index % 2 === 0
                                                    ? 'white'
                                                    : '#f0f0f0' }} >
                                        <TableCell style={tableCellStyle}>{(index + 1 + (currentPage - 1) * itemsPerPage)
                                            .toString().padStart(4, '0')}</TableCell>
                                        <TableCell style={tableCellStyle}>{customer.customerName}</TableCell>
                                        <TableCell style={tableCellStyle}>{customer.contactNumber}</TableCell>
                                        <TableCell style={tableCellStyle}>{customer.address1}</TableCell>
                                        <TableCell style={tableCellStyle}>{customer.city}</TableCell>
                                        <TableCell style={tableCellStyle}>{customer.state}</TableCell>
                                        <TableCell style={tableCellStyle}>{customer.pinCode}</TableCell>
                                        <TableCell style={tableCellStyle}>{customer.createdAt}</TableCell>
                                        <TableCell style={tableCellStyle}>
                                            <IconButton onClick={() => handleEditCustomer(customerList[index]._id, index + (currentPage - 1) * itemsPerPage)} >
                                                <ModeEditOutline className={styles.editIcon} />
                                            </IconButton>
                                            <IconButton onClick={() => handleConfirmDeleteOpen(customer._id)} >
                                                <Delete className={styles.editDelete} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={(event, value) => setCurrentPage(value)}
                            color="primary"
                           
                            sx={{
                                '& .MuiPaginationItem-root': {
                                    color: '#009999',
                                    border: '1px solid #ddd',
                                    margin: '0 4px',
                                    borderRadius: '4px',
                                },
                                '& .Mui-selected': {
                                    backgroundColor: '#009999',
                                    color: 'white',
                                },
                            }}
                        />
                    </Box>
                </div>

                <Snackbar
                    open={errorSnackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setErrorSnackbarOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
                        Please fill in all fields correctly.
                    </Alert>
                </Snackbar>

                <Dialog
                    open={confirmDeleteOpen}
                    onClose={handleConfirmDeleteClose}
                >
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this customer list?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleConfirmDeleteClose} color="primary">
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleDeleteCustomer} color="error">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* ====================================================alert======================================================= */}

                {alertInfo.open && (
                    <Alert
                        severity={alertInfo.severity}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => setAlertInfo({ ...alertInfo, open: false })}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        icon={alertInfo.severity === 'success' ? <CheckCircleOutlineIcon /> : <WarningIcon />}
                        sx={{
                            position: 'fixed',
                            top: 16,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 9999,
                        }}
                    >
                        {alertInfo.message}
                    </Alert>
                )}
            </div>
        </div>
    );
};
export default Customer;
