import React, { useReducer, useState, useEffect } from 'react';
import {
  Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar,
  Box, Grid, Select, MenuItem, FormControl, InputLabel,
  FormHelperText,
} from '@mui/material';
import { MdOutlineAddchart, MdSearch, } from 'react-icons/md';
import Pagination from '@mui/lab/Pagination';
import styles from "../quotation/quotation.module.css";
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete } from '@mui/material';
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import AddIcon from '@mui/icons-material/Add';
import DialogProduct from '../../DIALOG/dialogproduct';
import { Delete, ModeEditOutline, Preview } from '@mui/icons-material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { showDeleteConfirmation } from '../../DIALOG/sweetAlert';
import InvoiceQuotation from './Invoicequotation';

const apiurl = process.env.REACT_APP_API_URL;

const initialState = {
  formValues: {
    storeId: '',  // Add this line
    storeName: '',
    customerName: '',
    contactNo: '',
    email: '',
    customerAddress: '',
    city: '',
    state: '',
    pinCode: '',
    extraAmountAdd: '',
    discount: '',
    grandTotal: '',
  },
  errors: {},
};

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM_VALUES':
      return { ...state, formValues: { ...state.formValues, ...action.payload } };
    case 'SET_ERRORS':
      return { ...state, errors: { ...state.errors, ...action.payload } };
    case 'RESET_FORM':
      return {
        ...state,
        formValues: { ...initialState.formValues, storeName: '', productName: '' },
        errors: {}
      };
    default:
      return state;
  }
};

const QuotationFormat = () => {
  const [quotationList, setQuotationList] = useState([]);
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [imageFile, setImageFile] = useState(null);
  const [imageFileName, setImageFileName] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [itemNo, setItemNo] = useState('');
  const [currentQuotationNumber, setCurrentQuotationNumber] = useState(1001);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [quotationData, setQuotationData] = useState(null);
  const [othersAmount, setOthersAmount] = useState('');

  const [stores, setStores] = useState([]);
  const [productsData, setproductsData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [quotationId, setquotationId] = useState(null);
  const [states, setStates] = useState([]);
  const [showCategoryDialog, setShowCategoryDialog] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [addProductDialogOpen, setAddProductDialogOpen] = useState(false); //add product dialog box open


  const [isLoading, setIsLoading] = useState(false);

  //===================================== formatIndianCurrency ======================================================================
  const formatIndianCurrency = (x) => {
    if (x === undefined || x === null) return '0.00';
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  ////////////////////================================loading===================================================================
  const LoadingOverlay = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex:9999
    }}>
      <CircularProgress />
    </div>
  );

  const [zoomedImage, setZoomedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setZoomedImage(imageUrl);
  };

  const handleCloseZoom = () => {
    setZoomedImage(null);
  };

  const [products, setProducts] = useState([{
    id: '',
    productName: '',
    category: '',
    rate: '',
    pieces: '',
    amount: '',
    size: '',
  }]);


  const isRowFilled = (product) => {
    // Check if at least productName and rate are filled
    const isFilled = product.amount;
    console.log('Row filled check:', product, isFilled);
    return isFilled;
  };


  const addProductLine = () => {
    if (products.length === 0 || isRowFilled(products[products.length - 1])) {
      const newProduct = {
        id: '',
        productName: '',
        category: '',
        image: '',
        size: '',
        rate: '',
        pieces: '',
        amount: ''
      };
      setProducts([...products, newProduct]);
    } else {
      alert("Please fill out the current row before adding a new one.");
    }
  };

  //=================================================user api==============================================================
  const [userRole, setUserRole] = useState('');
  const [userStore, setUserStore] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${apiurl}user/loggeduser`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setUserRole(data.user.roleName.toLowerCase());
        if (data.user.roleName.toLowerCase() === 'store') {
          setUserStore({
            id: data.user.storeId,
            storeName: data.user.storeName
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);
  //================================alert =====================================================================================
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });

  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
    setTimeout(() => setAlertInfo({ open: false, message: '', severity: 'success' }), 5000); // Close after 5 seconds
  };

  //====================================================================================

  const commonTextFieldStyles = {
    '& .MuiOutlinedInput-root': {
      height: '40px',
      '& fieldset': {
        borderColor: '#888',
      },
      '&:hover fieldset': {
        borderColor: '#555',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#009999',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#555',
      '&.Mui-focused': {
        color: '#009999',
      },
    },
    '& .MuiInputBase-input': {
      color: '#333',
    },
  };


  const handleProductChange = (index, field, value) => {
    if (['pieces', 'rate',].includes(field)) {
      if (!/^\d*\.?\d*$/.test(value)) {
        // If the input is not a valid number, don't update the state
        return;
      }
    }
    const newProducts = [...products];

    if (field === 'productName') {
      const selectedProduct = productsData.find(p => p.id === value);
      if (selectedProduct) {
        newProducts[index] = {
          ...newProducts[index],
          id: selectedProduct.id,
          productName: selectedProduct.productName,
          category: selectedProduct.category,
        };
      }
    } else {
      newProducts[index][field] = value;
    }

    // Calculate amount if rate and pieces are present
    if (newProducts[index].rate && newProducts[index].pieces) {
      const rate = parseFloat(newProducts[index].rate) || 0;
      const pieces = parseFloat(newProducts[index].pieces) || 0;
      const discount = parseFloat(newProducts[index].discount) || 0;
      const amount = rate * pieces - discount;
      newProducts[index].amount = amount.toFixed(2);
    }

    setProducts(newProducts);
  };

  const tableCellStyle = {
    height: '25px',
    padding: '4px',  // Ensure no extra padding affects height
    textAlign: 'center',
    fontSize: '13px',
    border: '1px solid #333'

  };

  const calculateTotals = () => {
    const subtotal = products.reduce((sum, product) => {
      const amount = parseFloat(product.amount || 0);
      return sum + amount;
    }, 0);

    const extraAmountAddValue = parseFloat(othersAmount) || 0;
    const discountValue = parseFloat(totalDiscount) || 0;
    const subtotalWithExtras = subtotal + extraAmountAddValue;
    const grandTotal = Math.max(subtotalWithExtras - discountValue, 0).toFixed(2);

    return {
      subtotal: subtotal.toFixed(2),
      extraAmountAdd: extraAmountAddValue.toFixed(2),
      grandTotal: grandTotal
    };
  };

  const [query, setQuery] = useState('');

  const fetchQuotations = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);

    try {
      let url = new URL(`${apiurl}quotation`);

      // Add parameters to the URL
      url.searchParams.append('page', currentPage);
      url.searchParams.append('limit', rowsPerPage);

      if (fromDate) {
        url.searchParams.append('fromDate', fromDate);
      }
      if (toDate) {
        url.searchParams.append('toDate', toDate);
      }
      if (query) {
        url.searchParams.append('query', query);
      }

      // console.log('Fetching URL:', url.toString()); // Log the full URL

      const response = await fetch(url.toString(), {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch quotations');
      }
      const data = await response.json();

      const sortedQuotation = data.quotations.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setQuotationList(sortedQuotation);

      // Update total pages
      const totalPages = data.numberOfPages || Math.ceil(data.totalQuotation / rowsPerPage);
      setTotalPages(totalPages);

      localStorage.setItem('product', (data?.totalQuotation));

    } catch (error) {
      console.error('Error fetching quotations:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    fetchQuotations();
    // setQuery(''); // Reset the search input after searching
  };


  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to first page when changing rows per page
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    fetchQuotations(); // Call this to fetch data for the new page
  };
  useEffect(() => {
    fetchQuotations();
  }, [currentPage, rowsPerPage]);

  //==================================================reset==============================
  // Add this new function to reset the form
  const resetForm = () => {
    dispatch({ type: 'RESET_FORM' });
    setProducts([{
      id: '',
      productName: '',
      category: '',
      rate: '',
      discount: '',
      pieces: '',
      amount: '',
      size: ''
    }]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (['contactNo', 'totalDiscount',].includes(name)) {
      const isNumeric = /^\d*$/.test(value);
      if (!isNumeric) return;

    } if (name === 'storeId') {
      if (userRole === 'store') {
        dispatch({
          type: 'SET_FORM_VALUES',
          payload: {
            storeId: userStore.id,
            storeName: userStore.storeName
          }
        });
      } else {
        const selectedStore = stores.find(store => store.id === value);
        dispatch({
          type: 'SET_FORM_VALUES',
          payload: {
            storeId: value,
            storeName: selectedStore ? selectedStore.storeName : ''
          }
        });
      }
    } else {
      dispatch({ type: 'SET_FORM_VALUES', payload: { [name]: value } });
    }
    dispatch({ type: 'SET_ERRORS', payload: { [name]: '' } });
  };


  const validateForm = () => {
    const { storeId, customerName, contactNo, } = state.formValues;
    const newErrors = {};
    // console.log(state.formValues)
    if (!customerName) newErrors.customerName = 'Customer Name is required';
    if (!contactNo) newErrors.contactNo = 'Contact is required';
    // Only validate storeId for admin and staff roles
    if (userRole !== 'store' && !storeId) {
      newErrors.storeId = 'Store Name is required';
    }
    // console.log('Validation errors:', newErrors);

    dispatch({ type: 'SET_ERRORS', payload: newErrors });
    return Object.keys(newErrors).length === 0;
  };

  const handleAddOrUpdateDetails = async (e) => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
    e.preventDefault();

    if (!validateForm()) {
      setErrorSnackbarOpen(true);
      setIsLoading(false);
      return;
    }

    const newQuotation = {
      storeId: userRole === 'store' ? userStore.id : parseInt(state.formValues.storeId),
      customerName: state.formValues.customerName,
      contactNo: state.formValues.contactNo,
      email: state.formValues.email,
      customerAddress: state.formValues.customerAddress,
      state: state.formValues.state,
      city: state.formValues.city,
      pinCode: state.formValues.pinCode,
      extraAmountAdd: parseFloat(othersAmount) || 0,
      grandTotal: parseFloat(calculateTotals().grandTotal),
      discount: parseFloat(totalDiscount) || 0,

      products: products.map(product => {
        // console.log("Mapping product:", { id: (product.id) });
        return {
          id: parseInt(product.id),
          pieces: parseInt(product.pieces),
          rate: parseFloat(product.rate),
          amount: parseFloat(product.amount),
          size: product.size
        };
      })
    };
    // Add quotationId only when updating
    if (editIndex !== null) {
      newQuotation.quotationId = quotationId;
    }
    try {
      let response; // Declare response variable
      if (editIndex !== null) {
        // Update existing quotation
        response = await fetch(`${apiurl}quotation/${quotationId}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },

          body: JSON.stringify(newQuotation),
        });

      } else {
        // Add new quotation
        response = await fetch(`${apiurl}quotation`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },

          body: JSON.stringify(newQuotation),
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to add/update quotation: ${errorData.message || response.statusText}`);
      }

      const updatedQuotation = await response.json();
      // console.log('quotation added/updated successfully', updatedQuotation);

      // Add the new quotation to the list
      setQuotationList(prevList => {
        if (editIndex !== null) {
          // Update existing quotation
          showAlert('Quotation Order updated successfully', 'success');
          return prevList.map(quotation =>
            quotation._id === updatedQuotation._id ? updatedQuotation : quotation
          );
        } else {
          // Add new quotation
          showAlert('Quotation Order added successfully', 'success');
          return [updatedQuotation, ...prevList];
        }
      });

      resetForm();
      setShowForm(false);
      setEditIndex(null);
      setquotationId(null);

      fetchQuotations();

    } catch (error) {
      showAlert(`Failed to ${editIndex !== null ? 'update' : 'add'} product: ${error.message}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };


  const handlePreviewOpen = async (quotationId) => {
    try {
      const response = await fetch(`${apiurl}quotation/${quotationId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch quotations details');
      const data = await response.json();
      setQuotationData(data?.quotation);
      setPreviewOpen(true);
    } catch (error) {
      console.error('Error fetching quotations details:', error);
    }
  };


  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setQuotationData(null);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleConfirmDeleteOpen = (quotationId) => {
    setDeleteIndex(quotationId);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
    setDeleteIndex(null);
  };

  const handleDeleteDetails = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
    try {
      if (!deleteIndex) {
        throw new Error('Invalid product selected for deletion');
      }

      const response = await fetch(`${apiurl}quotation/${deleteIndex}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete quotation');
      }


      console.log('Quotation deleted successfully');

      // Update the Quotation list
      setQuotationList((prevQuotationList) => {
        const updatedList = prevQuotationList.filter((quotation) => quotation._id !== deleteIndex);

        // If the current page is now empty and it's not the first page, go to the previous page
        if (updatedList.length === 0 && currentPage > 1) {
          setCurrentPage(prev => prev - 1);
        }

        return updatedList;
      });

      // Fetch the updated Quotation list
      fetchQuotations();

      showAlert('Quotation deleted successfully', 'success');

    } catch (error) {
      console.error('Error deleting Quotation:', error);
      showAlert(`Failed to delete Quotation: ${error.message}`, 'error');
    } finally {
      setConfirmDeleteOpen(false);
      setDeleteIndex(null);
      setIsLoading(false);
    }
  };

  const handleDetailList = (_id, index) => {
    setquotationId(_id);

    const actualIndex = (currentPage - 1) * itemsPerPage + index;
    // Use the _id directly to find the product instead of relying on the newly set state
    const detailsToEdit = quotationList.find(quotation => quotation._id === _id);

    setquotationId(detailsToEdit.quotationId); // Ensure this is set correctly

    if (!detailsToEdit) {
      console.error('Quotation order not found:', _id, index, actualIndex);
      showAlert('Error: Quotation order not found', 'error');
      return;
    }

    dispatch({
      type: 'SET_FORM_VALUES',
      payload: {
        storeId: detailsToEdit?.storeId || '',
        customerName: detailsToEdit?.customerName || '',
        customerAddress: detailsToEdit?.customerAddress || '',
        contactNo: detailsToEdit?.contactNo || '',
        email: detailsToEdit?.email || '',
        city: detailsToEdit?.city || '',
        state: detailsToEdit?.state || '',
        pinCode: detailsToEdit?.pinCode || '',

      }
    });

    // Set the products array with the existing products
    if (detailsToEdit?.quotationDetails && detailsToEdit?.quotationDetails[0]?.length > 0) {
      console.log("salesData", detailsToEdit.products);
      setProducts(detailsToEdit.quotationDetails[0].map(product => ({
        _id: product?._id,
        id: product.productId, // Add this line
        productName: product.productName || '',
        category: product.categoryName,
        image: product.image || '',
        size: product.size || '',
        rate: product.rate || '',
        pieces: product.pieces || '',
        discount: product.discount || '',
        amount: product.amount || '',
      })));
    } else {
      setProducts([]);
    }

    setShowForm(true);
    setEditIndex(quotationList.findIndex(quotation => quotation._id === _id));
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  //store define api
  const fetchstores = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${apiurl}store`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch stores');
      }
      const data = await response.json();
      // console.log('Fetched store data:', data);

      if (data.status === 'success' && Array.isArray(data.stores)) {
        const storeList = data.stores.map(store => ({
          id: store.storeId, // या store.id, जो भी आपके API में उपयोग हो रहा है
          storeName: store.storeName,
          city: store.city
        }));
        setStores(storeList);
      } else {
        // console.error('Unexpected data format:', data);
        setStores([]);
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
      setStores([]);
    }
  };
  useEffect(() => {
    fetchstores();
  }, []);

  useEffect(() => {
    // console.log('Updated stores:', stores);
  }, [stores]);


  //product define api
  const fetchproducts = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${apiurl}product/list`, {

        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      // console.log('Fetched product data:', data);

      if (data.status === 'success' && Array.isArray(data.products)) {
        const productList = data.products.map(product => ({
          id: product.productId,
          productName: product.productName,
          category: product.categoryName,
          image: product.image,

        }));
        setproductsData(productList);
      } else {
        // console.error('Unexpected data format:', data);
        setproductsData([]);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setproductsData([]);
    }
  };
  useEffect(() => {
    // console.log('Calling fetchproducts');
    fetchproducts();
  }, []);

  useEffect(() => {
    // console.log('Updated productsData:', productsData);
  }, [productsData]);


  //===================================================states define api======================================================
  const fetchStates = async () => {
    const token = localStorage.getItem('token');

    try {
      // console.log('Fetching states...');
      const response = await fetch(`${apiurl}state`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },

      });
      // console.log('Response status:', response.status);
      if (!response.ok) {
        throw new Error('Failed to fetch state');
      }
      const data = await response.json();
      // console.log('Fetched state data:', data);
      if (Array.isArray(data) && data.length > 0) {
        // console.log('First state item:', data[0]);
      } else {
        // console.log('Data is not an array or is empty');
      }
      setStates(data);
    } catch (error) {
      // console.error('Error fetching state:', error);
    }
  };

  useEffect(() => {
    // console.log('Calling fetchStates');
    fetchStates();
  }, []);

  //----------------------------------------api category and unit---------------------------------------------------------------------
  //catogries define api 

  const fetchCategory = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${apiurl}category`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },

      });
      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }
      const data = await response.json();
      // console.log('Categories data:', data);
      if (data.status === 'success' && Array.isArray(data.categories)) {
        setCategories(data.categories);
      } else {
        // console.error('Unexpected data format for categories:', data);
        setCategories([]);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      setCategories([]);
    }
  };
  useEffect(() => {
    fetchCategory();
  }, []);


  // ===================================================category=============================================================================
  const handleAddCategory = async () => {
    if (newCategory.trim() !== '') {
      const token = localStorage.getItem('token');
      setIsLoading(true);
      try {
        const response = await fetch(`${apiurl}category`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name: newCategory })
        });
        if (!response.ok) {
          throw new Error('Failed to add category');
        }
        const data = await response.json();
        if (data.status === 'success') {
          // Update the categories state immediately
          setCategories(prevCategories => [...prevCategories, data.category]);
          handleInputChange({ target: { name: 'category', value: newCategory } });

          setNewCategory('');
          setShowCategoryDialog(false);
          showAlert('Category added successfully', 'success');
        }
      } catch (error) {
        console.error('Error adding category:', error);
        showAlert(`Failed to add category: ${error.message}`, 'error');
      } finally {
        setIsLoading(false);
      }
    }
  };

  //=========================================================product api============================================================

  const handleAddOrUpdateProduct = async (e) => {
    const token = localStorage.getItem('token');
    setIsLoading(true);

    const formData = new FormData();
    formData.append('productName', state.formValues.productName);
    const selectedCategory = categories.find(cat => cat.name === state.formValues.category);

    if (selectedCategory) {
      formData.append('categoryId', selectedCategory.categoryId);
    } else {
      console.error('Selected category not found');
      showAlert('Invalid category selected', 'error');
      setIsLoading(false);
      return;
    }
    if (imageFile) {
      formData.append('image', imageFile);
    } else {
      formData.append('image', state.formValues.image);
    }

    try {
      const response = await fetch(`${apiurl}product`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to add product: ${errorData.message || response.statusText}`);
      }

      const newProduct = await response.json();

      // Update the productsData state
      setproductsData(prevProducts => [...prevProducts, newProduct]);

      // Reset form fields
      dispatch({ type: 'RESET_FORM' });
      setImageFile(null);
      setImageFileName('');

      // Show success alert
      showAlert('Product added successfully', 'success');

      // Close the add product dialog
      setAddProductDialogOpen(false);

      // Fetch updated product list
      await fetchproducts();

    } catch (error) {
      console.error('Error adding product:', error);
      showAlert(`Failed to add product: ${error.message}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImageFileName(file.name);
    }
  };
  //-==============================================remove product================================================================


  const removeProductLine = async (product) => {
    const token = localStorage.getItem('token');

    // Use the _id field from the product
    const productDetailId = product._id;

    // Immediately remove the product from the UI, even if the ID is missing
    setProducts((prevProducts) => {
      const updatedProducts = prevProducts.filter((p) => p !== product);
      console.log('Updated products list:', updatedProducts);
      return updatedProducts;
    });

    // If the product has an _id, proceed with the API call to delete it from the server
    if (productDetailId) {
      try {
        console.log('Attempting to delete product with _id:', productDetailId);

        const response = await fetch(`${apiurl}quotation/product/${productDetailId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.error('Server response:', errorData);
          console.error('Response status:', response.status);
          console.error('Response statusText:', response.statusText);
          throw new Error(errorData.message || `Failed to delete product: ${response.statusText}`);
        }

        console.log('Product deleted from the server successfully');
      } catch (error) {
        console.error('Error deleting product from the server:', error);
        throw error;
      }
    } else {
      console.warn('Product does not have an _id, skipping server deletion');
    }
  };

  /////////=======================================================================================================================


  return (
    <div className={styles.container}>
      <div className={styles.maincontainer}>
        {isLoading && <LoadingOverlay />}
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              name="fromDate"
              label="From Date"
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                shrink: true // This ensures the label stays above the input value
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="toDate"
              label="To Date"
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                shrink: true // This ensures the label stays above the input value
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="query"
              label="Search by Customer Name or Contact"
              value={query}
              onChange={handleQueryChange}
              InputProps={{
                className: styles.invoiceinput,
              }}
              InputLabelProps={{
                className: styles.invoicelabel,
                shrink: true
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
              startIcon={<MdSearch />}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>

          <Grid item >
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
              startIcon={<MdOutlineAddchart />}
              onClick={() => setShowForm(true)}
            >
              Add Quotation
            </Button>
          </Grid>
        </Grid>

        <FormControl variant="outlined" size="small" sx={{ minWidth: 120, maxHeight: 5, marginBottom: 5, marginTop: 3 }}>
          <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
          <Select
            labelId="rows-per-page-label"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            label="Rows per page"
          >
            {[10, 25, 50, 100].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={styles.containers}>
          <Dialog
            open={showForm}
            onClose={() => { }}
            maxWidth="lg"
            height='250rem'
            fullWidth
            PaperProps={{
              sx: {
                maxWidth: 'auto',
                maxHeight: 'auto',
                margin: 'auto',
                mt: 0, // Set the margin-top to 0
              },
            }}
            sx={{
              display: 'flex',
              alignItems: 'flex-start', // Align dialog to the top
              justifyContent: 'center',
            }}
          >
            <DialogContent>
                <div className={styles.invoicecontentContainer}>
                  <h2 className={styles.invoiceheader}>Quotation Data</h2>
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    sx={{ mt: -5, }}
                  >


                    <Grid container spacing={2}>

                      <Grid item xs={2}>
                        <FormControl
                          fullWidth
                          error={!!state.errors.storeId}
                          disabled={userRole === 'store'}
                        >
                          <InputLabel sx={{ top: '-6px', color: '#555', '&.Mui-focused': { color: '#009999' } }}>Store <span style={{ color: 'red' }}>*</span></InputLabel>

                          <Select
                            name="storeId"
                            value={userRole === 'store' ? userStore?.id : state.formValues.storeId}
                            onChange={handleInputChange}
                            label="Store"
                            className={styles.invoiceinput}
                            required={userRole !== 'store'}
                          >
                            {userRole === 'store' ? (
                              <MenuItem value={userStore?.id}>{userStore?.storeName}</MenuItem>
                            ) : (
                              [
                                <MenuItem key="none" value=""><em>None</em></MenuItem>,
                                ...stores.map((store) => (
                                  <MenuItem key={store.id} value={store.id}>
                                    {store.storeName}
                                  </MenuItem>
                                ))
                              ]
                            )}
                          </Select>
                          {state.errors.storeId && (
                            <FormHelperText>{state.errors.storeId}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={2}>
                        <TextField
                          name="customerName"
                          label="Customer Name"
                          value={state.formValues.customerName}
                          onChange={handleInputChange}
                          fullWidth
                          required
                          error={!!state.errors.customerName}
                          helperText={state.errors.customerName} InputProps={{ className: styles.invoiceinput }}
                          sx={{
                            '& .MuiInputLabel-root': {
                              top: '-6px',
                              color: '#555',
                              '&.Mui-focused': {
                                color: '#009999',
                              },
                              // Hide the default asterisk and apply custom styles
                              '& .MuiFormLabel-asterisk': {
                                color: 'red', // Customize the color of the asterisk
                              },
                            },
                            '& .MuiInputBase-root': {
                              color: '#000', // Text color
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          name="contactNo"
                          label="Contact No"
                          value={state.formValues.contactNo}
                          onChange={handleInputChange}
                          fullWidth
                          required
                          inputProps={{
                            pattern: "[0-9]*",
                            inputMode: "numeric",
                            maxLength: 10,
                          }}
                          error={!!state.errors.contactNo}
                          helperText={state.errors.contactNo}
                          InputProps={{ className: styles.invoiceinput }}
                          sx={{
                            '& .MuiInputLabel-root': {
                              top: '-6px',
                              color: '#555',
                              '&.Mui-focused': {
                                color: '#009999',
                              },
                              // Hide the default asterisk and apply custom styles
                              '& .MuiFormLabel-asterisk': {
                                color: 'red', // Customize the color of the asterisk
                              },
                            },
                            '& .MuiInputBase-root': {
                              color: '#000', // Text color
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          name="email"
                          label="Email"
                          type="email"
                          value={state.formValues.email}
                          onChange={handleInputChange}
                          fullWidth
                          error={!!state.errors.email}
                          helperText={state.errors.email}
                          InputProps={{ className: styles.invoiceinput }}
                          sx={{
                            '& .MuiInputLabel-root': {
                              top: '-6px',
                              color: '#555',
                              '&.Mui-focused': {
                                color: '#009999',
                              },
                            },
                            '& .MuiInputBase-root': {
                              color: '#000', // Adjust text color if needed
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          name="customerAddress"
                          label="Customer Address"
                          value={state.formValues.customerAddress}
                          onChange={handleInputChange}
                          fullWidth
                          error={!!state.errors.customerAddress}
                          helperText={state.errors.customerAddress}
                          InputProps={{ className: styles.invoiceinput }}
                          sx={{
                            '& .MuiInputLabel-root': {
                              top: '-6px',
                              color: '#555',
                              '&.Mui-focused': {
                                color: '#009999',
                              },
                            },
                            '& .MuiInputBase-root': {
                              color: '#000', // Adjust text color if needed
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <FormControl fullWidth error={!!state.errors.state}>
                          <InputLabel sx={{ top: '-6px', color: '#555', '&.Mui-focused': { color: '#009999' } }}>State</InputLabel>
                          <Select
                            name="state"
                            value={state.formValues.state}
                            onChange={(e) => {
                              handleInputChange(e);
                              // console.log('State updated:', e.target.value);
                            }}
                            label="State"
                            className={styles.invoiceinput}                  >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {Array.isArray(states) ? (
                              states.map((stateItem) => (
                                <MenuItem key={stateItem.id || stateItem._id} value={stateItem.state || stateItem.name}>
                                  {stateItem.state || stateItem.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value=""><em>No states available</em></MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          name="city"
                          label="City"
                          value={state.formValues.city}
                          onChange={handleInputChange}
                          fullWidth
                          error={!!state.errors.city}
                          helperText={state.errors.city} InputProps={{ className: styles.invoiceinput }}
                          sx={{
                            '& .MuiInputLabel-root': {
                              top: '-6px',
                              color: '#555',
                              '&.Mui-focused': {
                                color: '#009999',
                              },
                            },
                            '& .MuiInputBase-root': {
                              color: '#000', // Adjust text color if needed
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          name="pinCode"
                          label="Pincode"
                          value={state.formValues.pinCode}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Allow only numbers and limit to 6 digits
                            if (value === '' || /^[0-9]{0,6}$/.test(value)) {
                              handleInputChange(e);
                            }
                          }}
                          fullWidth
                          inputProps={{
                            pattern: "[0-9]{6}",
                            inputMode: "numeric",
                            maxLength: 6,
                          }}
                          error={!!state.errors.pinCode}
                          InputProps={{ className: styles.invoiceinput }}
                          sx={{
                            '& .MuiInputLabel-root': {
                              top: '-6px',
                              color: '#555',
                              '&.Mui-focused': {
                                color: '#009999',
                              },
                            },
                            '& .MuiInputBase-root': {
                              color: '#000', // Adjust text color if needed
                            },
                          }}
                        />
                      </Grid>

                    </Grid>

                  </Box>
                  <div className={styles.ScrollTable}>
                  <table className={styles.invoicetabledata}>
                    <thead className={styles.tableHeader}>
                      <tr>
                        <th className={styles.invoiceTh}>Sr_No</th>
                        <th className={styles.invoiceTh}>Product Name</th>
                        <th className={styles.invoiceTh}>Category</th>
                        <th className={styles.invoiceTh}>Image</th>
                        <th className={styles.invoiceTh}>Size</th>
                        <th className={styles.invoiceTh}>Rate</th>
                        <th className={styles.invoiceTh}>Pieces</th>
                        <th className={styles.invoiceTh}>Amount</th>
                        <th className={styles.invoiceTh}>Action</th>
                      </tr>
                    </thead>


                    <tbody>
                      {products.map((product, index) => {
                        const selectedProductIds = products.map(p => p.id); // List of selected products

                        return (
                          <tr key={index}>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ddd' }}>
                              {index + 1}
                            </td>

                            <td className={styles.invoiceTd}>
                              <Autocomplete
                                options={[{ id: 'add-new', productName: '+ Add a New Product', category: '', image: '' }, ...productsData.filter(p => !selectedProductIds.includes(p.id) || p.id === product.id)]} // Include 'Add a New Product' option and filter others
                                getOptionLabel={(option) => option.productName || ''}
                                value={productsData.find(p => p.id === product.id) || null}
                                onChange={(event, newValue) => {
                                  if (newValue && newValue.id === 'add-new') {
                                    setAddProductDialogOpen(true); // Open dialog for adding a new product
                                  } else {
                                    // Update the product fields when a product is selected
                                    handleProductChange(index, 'id', newValue ? newValue.id : '');
                                    handleProductChange(index, 'productName', newValue ? newValue.productName : '');
                                    handleProductChange(index, 'category', newValue ? newValue.category : '');
                                    handleProductChange(index, 'image', newValue ? newValue.image : '');
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Type to search..."
                                 
                                    sx={{
                                      width: '220px', // Adjust this value as needed
                                      '& .MuiOutlinedInput-root': {
                                        height: "30px",
                                        borderRadius: '0',
                                        '& fieldset': {
                                          borderColor: '#888',
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: '#333',
                                        },
                                      },
                                      '& .MuiInputBase-input': {
                                        width: "100%",
                                      },
                                    }}
                                  />
                                )}
                                renderOption={(props, option) => (
                                  <li
                                    {...props}
                                    style={{
                                      backgroundColor: option.id === 'add-new' ? '#009999' : 'white',
                                      color: option.id === 'add-new' ? '#ffffff' : '#000000', // White text for 'Add a New Product'
                                      fontFamily: option.id === 'add-new' ? 'Arial, sans-serif' : 'inherit', // Custom font for 'Add a New Product'
                                      fontSize: option.id === 'add-new' ? '14px' : 'inherit', // Custom font size for 'Add a New Product'
                                      fontWeight: option.id === 'add-new' ? 'semibold' : 'normal',
                                    }}
                                  >
                                    {option.productName}
                                  </li>
                                )}
                                filterOptions={(options, { inputValue }) =>
                                  options.filter(option =>
                                    option.productName.toLowerCase().startsWith(inputValue.toLowerCase())
                                  )
                                }
                              />
                            </td>
                    


                    <td className={styles.invoiceTd}>
                      <input
                        type="text"
                        value={product.category || ''}
                        disabled
                        className={styles.invoiceinputs}
                      />
                    </td>
                    <td className={styles.invoiceTd}>
                      {product.image ? (
                        <div style={{ position: 'relative', width: '50px', height: '50px' }}>
                          <img
                            src={product.image}
                            alt=''
                            style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
                            onClick={() => handleImageClick(product.image)}
                          />
                          <IconButton
                            size="small"
                            style={{ position: 'absolute', right: 0, bottom: 0 }}
                            onClick={() => handleImageClick(product.image)}
                          >
                            <ZoomInIcon fontSize="small" />
                          </IconButton>
                        </div>
                      ) : (
                        <span>No Img</span>
                      )}
                    </td>

                    <td className={styles.invoiceTd}>
                      <input
                        type="text"
                        value={product.size || ''}
                        onChange={(e) => handleProductChange(index, 'size', e.target.value)}
                        className={styles.invoiceinputs}
                      />
                    </td>
                    <td className={styles.invoiceTd}>
                      <input
                        type="text"
                        value={formatIndianCurrency(product.rate)}
                        onChange={(e) => {
                          const value = e.target.value.replace(/,/g, ''); // Remove commas if any

                          // Allow only numbers and ensure it doesn't exceed 11 digits
                          if (value === '' || /^\d{1,11}(\.\d{0,2})?$/.test(value)) {
                            handleProductChange(index, 'rate', value); // Call the change handler with valid input
                          }
                        }}
                        className={styles.invoiceinputs}
                        style={{ textAlign: 'right' }}
                      />
                    </td>

                    <td className={styles.invoiceTd}>
                      <input
                        value={product.pieces}
                        onChange={(e) => handleProductChange(index, 'pieces', e.target.value)}
                        className={styles.invoiceinputs}
                        style={{ textAlign: 'right' }}
                      />
                    </td>

                    <td className={styles.invoiceTd}>
                      <input
                        type="text"
                        value={formatIndianCurrency(product.amount)}
                        readOnly
                        className={styles.invoiceinputs}
                      />
                    </td>

                    <td className={styles.invoiceTd}>
                      <IconButton
                        onClick={(event) => showDeleteConfirmation(() => removeProductLine(product))}
                        className={styles.edeleteButton}>
                        <ClearIcon className={styles.invoiceicons} />
                      </IconButton>
                    </td>
                  </tr>
                  );
                      })}
                </tbody>
              </table>


              <Button sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }} onClick={addProductLine}>+ Add Line</Button>
              <div className={styles.summary}>
                <div>Sub Total: <span>₹{formatIndianCurrency(calculateTotals().subtotal)}</span></div>
                <div>Extra Amount: <span>₹{formatIndianCurrency(calculateTotals().extraAmountAdd)}</span></div>
                <div>
                  Total Discount:
                  <input
                    value={formatIndianCurrency(totalDiscount)}
                    onChange={(e) => {
                      const value = e.target.value.replace(/,/g, ''); // Remove commas for parsing

                      // Allow only numbers and ensure it doesn't exceed 11 digits
                      if (value === '' || /^[0-9]{0,11}$/.test(value)) {
                        setTotalDiscount(parseFloat(value) || 0); // Update state if valid
                      }
                    }}
                    className={styles.invoiceinputs}
                    style={{ width: '80px', marginLeft: '10px', textAlign: 'right' }}
                  />

                </div>
                <div>Grand Total: <span>₹{formatIndianCurrency(calculateTotals().grandTotal)}</span></div>
              </div>

              </div>

              <DialogActions sx={{ mt: 2, justifyContent: "flex-start" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#009999',
                    color: '#fff',
                    textTransform: 'capitalize',
                    '&:hover': {
                      backgroundColor: '#01dcb8',
                    },
                  }}
                  onClick={handleAddOrUpdateDetails}
                  startIcon={<AddIcon />}

                >
                  {editIndex !== null ? 'Update Quotation' : 'Add Quotation'}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#e32e2e',
                    color: "fff",
                    textTransform: 'capitalize',
                    '&:hover': {
                      backgroundColor: '#ff0000',
                    },
                  }}

                  onClick={() => {
                    dispatch({ type: 'RESET_FORM' });
                    setImageFileName('');
                    setShowForm(false);
                    resetForm();
                    setEditIndex(null);
                  }}
                  startIcon={<CloseIcon />}

                >
                  Cancel
                </Button>
              </DialogActions>
            </div>
       
      </DialogContent>
    </Dialog>
        </div >

  { addProductDialogOpen && (
    <DialogProduct
      showForm={addProductDialogOpen}
      onClose={() => setAddProductDialogOpen(false)}
      onProductAdded={(newProduct) => {
        setAddProductDialogOpen(false);
      }}
      state={state}
      itemNo={itemNo}
      categories={categories}
      handleInputChange={handleInputChange}
      editIndex={editIndex}
      setCategories={setCategories}
      onSubmit={handleAddOrUpdateProduct}
      imageFileName={imageFileName}
      imageFile={imageFile}
      handleImageChange={handleImageChange}
      dispatch={dispatch}  // Add this line
      isLoading={isLoading}
      handleAddCategory={handleAddCategory}
      newCategory={newCategory}
      setNewCategory={setNewCategory}
      showCategoryDialog={showCategoryDialog}
      setShowCategoryDialog={setShowCategoryDialog}
    />

  )}

        <div className={styles.tablecontainer}>
          <TableContainer component={Paper}>
            <Table className={styles.storetable}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Sr No.</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Quotation No.</TableCell>
                  {userRole !== 'store' && (
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Store Name</TableCell>
                  )}                {/* <TableCell className={styles.tableHeader}>Store Address</TableCell> */}
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Customer Name</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Contact No</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Email</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Customer Address</TableCell>

                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Date</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Actions</TableCell>


                </TableRow>
              </TableHead>
              <TableBody>

                {quotationList?.map((quotation, index) => {
                  return (
                    <TableRow key={quotation._id} style={{ backgroundColor: !quotation.isActive ? '#ffcccc' : index % 2 === 0 ? 'white' : '#f0f0f0' }}>
                      <TableCell style={tableCellStyle}>{(index + 1 + (currentPage - 1) * itemsPerPage).toString().padStart(4, '0')}</TableCell>
                      <TableCell style={tableCellStyle}>{quotation.quotationNo || `Qt${currentQuotationNumber + index}`}</TableCell>

                      {/* <TableCell style={tableCellStyle}>
                      {quotation?.storeDetails?.map((store, idx) => (
                        <div key={idx}>{store.storeName}  </div>
                      ))}
                    </TableCell> */}
                      {userRole !== 'store' && (
                        <TableCell style={tableCellStyle}>{quotation.storeName}</TableCell>
                      )}
                      <TableCell style={tableCellStyle}>{quotation.customerName}</TableCell>
                      <TableCell style={tableCellStyle}>{quotation.contactNo}</TableCell>
                      <TableCell style={tableCellStyle}>{quotation.email}</TableCell>
                      <TableCell style={tableCellStyle}>{quotation.customerAddress}</TableCell>

                      <TableCell style={tableCellStyle}>{quotation.createdAt}</TableCell>
                      <TableCell style={tableCellStyle}>
                        <IconButton onClick={() => handlePreviewOpen(quotation._id)} >
                          <Preview className={styles.prew} />
                        </IconButton>
                        <IconButton onClick={() => handleDetailList(quotationList[index]._id, index + (currentPage - 1) * itemsPerPage)} >
                          <ModeEditOutline className={styles.editIcon} />
                        </IconButton>
                        <IconButton onClick={() => handleConfirmDeleteOpen(quotation._id)} >
                          <Delete className={styles.editDelete} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#009999',
                  border: '1px solid #ddd',
                  margin: '0 4px',
                  borderRadius: '4px',
                },
                '& .Mui-selected': {
                  backgroundColor: '#009999',
                  color: 'white',
                },
              }}
            />
          </Box>
        </div>

        <InvoiceQuotation
          quotationData={quotationData}
          previewOpen={previewOpen}
          handlePreviewClose={handlePreviewClose}
          handlePrint={handlePrint}
        />

        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setErrorSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
            Please fill in all fields correctly.
          </Alert>
        </Snackbar>

        <Dialog
          open={confirmDeleteOpen}
          onClose={handleConfirmDeleteClose}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this product?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleConfirmDeleteClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleDeleteDetails} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
{/* ====================================================alert======================================================= */ }

{
  alertInfo.open && (
    <Alert
      severity={alertInfo.severity}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => setAlertInfo({ ...alertInfo, open: false })}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      icon={alertInfo.severity === 'success' ? <CheckCircleOutlineIcon /> : <WarningIcon />}
      sx={{
        position: 'fixed',
        top: 16,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 9999,
      }}
    >
      {alertInfo.message}
    </Alert>
  )
}
      </div >

  {/* Image Zoom Dialog */ }
  < Dialog open = {!!zoomedImage} onClose = { handleCloseZoom } maxWidth = "md" >
    <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', padding: 0 }}>
      <img src={zoomedImage} alt="Zoomed product" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
    </DialogContent>
      </Dialog >
    </div >
  );
};

export default QuotationFormat;
