import React from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { MdPrint } from 'react-icons/md';
import { styled } from '@mui/system';
import fl from '../../assets/fl copy.png';


// Hide elements during print
const NoPrint = styled('div')({
    '@media print': {
        display: 'none !important',
    },
});


// Print-specific styles
const PrintContainer = styled('div')({
  fontFamily: 'Arial, sans-serif',
  position: 'relative',
  width: '100%',
  margin: 0,
  padding: '0 24px',
  backgroundColor: '#ffffff',
  '@media print': {
    padding: '16px',
    width: '100%',
    minHeight: '100%',
    backgroundColor: 'white',
    boxShadow: 'none',
    fontSize: '12pt',
    display: 'none !important',
  },
});

const Watermark = styled('div')({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%) rotate(-45deg)',
  fontSize: '100px',
  opacity: 0.1,
  pointerEvents: 'none',
  zIndex: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '@media print': {
    position: 'fixed',
    opacity: 0.05,
  },
});

const TableContainer = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
  marginBottom: '30px',
  '& th, & td': {
    padding: '8px',
    borderBottom: '1px solid #ddd',
  },
  '& th': {
    backgroundColor: '#f5f5f5',
    textAlign: 'left',
  },
  '& td': {
    fontSize: '0.775rem',
  },
});



const formatIndianCurrency = (x) => {
  if (x === undefined || x === null) return '0.00';
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const InvoicePage = ({ pageData, isLastPage }) => {
  const safePageData = pageData || {};
  const safeStoreDetails = safePageData.storeDetails?.[0] || {};
  const safeProducts = safePageData.products || [];

  const Footer = ({ safePageData }) => (
    <footer style={{ marginTop: 'auto', paddingTop: '20px' }}>
        <div style={{ marginBottom: '20px' }}>
            <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>Terms & Conditions :</div>
            <ul style={{ margin: 0, paddingLeft: '20px' }}>
                <li>All sales are final.</li>
                <li>No exchanges or refunds will be accepted.</li>
            </ul>
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px', fontStyle: 'italic', borderTop: "1px solid #ddd", paddingTop: '10px' }}>
            <h3>Thank you for your Business</h3>
        </div>
    </footer>
)

  return (
    <PrintContainer style={{ pageBreakAfter: isLastPage ? 'auto' : 'always' }}>
      <Watermark>
        <img src={fl} alt="" style={{ width: '80%', height: 'auto' }} />
      </Watermark>

      {/* Header */}
      <div style={{ fontSize: '20px', marginBottom: '20px', display: 'flex', justifyContent: 'center', color: '#666' }}>
        CASH MEMO
      </div>

      {/* Logo and Store Name */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <img src={fl} alt="" style={{ width: '40px', marginRight: '10px' }} />
          <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
            {safeStoreDetails.storeName}
          </div>
        </div>
      </div>

      {/* Invoice Details */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        {safePageData?.salseInvoiceNo && (
          <div style={{ color: '#666' }}>Memo NO. {safePageData.salseInvoiceNo}</div>
        )}
        {safePageData?.date && (
          <div style={{ color: '#666' }}>
            {new Date(safePageData.date).toLocaleDateString('en-GB')}
          </div>
        )}
      </div>

      {/* Store and Customer Details */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div>
          <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>Store Details:</div>
          <div>{safeStoreDetails.storeName}</div>
          <div>{safeStoreDetails.address}</div>
          <div>{[safeStoreDetails.state, safeStoreDetails.city, safeStoreDetails.pinCode].filter(Boolean).join(', ')}</div>
          <div>{safeStoreDetails.mobileNumber}</div>
        </div>
        <div style={{ textAlign: 'right' }}>
          <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>Customer Details:</div>
          <div>{safePageData.customerName}</div>
          <div>{safePageData.customerAddress}</div>
          <div>{[safePageData.city, safePageData.state, safePageData.pinCode].filter(Boolean).join(', ')}</div>
          <div>{safePageData.contactNo}</div>
        </div>
      </div>

      {/* Products Table */}
      <TableContainer>
        <thead>
          <tr>
            <th>Sr No</th>
            <th>Product Name</th>
            <th>Category</th>
            <th style={{ textAlign: 'right' }}>Rate</th>
            <th style={{ textAlign: 'right' }}>Pieces</th>
            <th style={{ textAlign: 'right' }}>Amount(₹)</th>
          </tr>
        </thead>
        <tbody>
          {safeProducts.map((product, idx) => (
            <tr key={idx}>
              <td>{product.srNo}</td>
              <td>{product.productName}</td>
              <td>{product.categoryName}</td>
              <td style={{ textAlign: 'right' }}>{formatIndianCurrency(product.rate)}</td>
              <td style={{ textAlign: 'right' }}>{product.pieces}</td>
              <td style={{ textAlign: 'right' }}>{formatIndianCurrency(product.amount)}</td>
            </tr>
          ))}
        </tbody>
      </TableContainer>

      {/* Totals */}
      {isLastPage && (
         <>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '30px' }}>
          <div style={{ width: '250px' }}>
            {safePageData.extraAmountAdd !== 0 && (
              <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
                <strong>Extra Amount:</strong>
                <span>{`₹ ${formatIndianCurrency(safePageData.extraAmountAdd)}`}</span>
              </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
              <strong>Discount:</strong>
              <span>{`₹ ${formatIndianCurrency(safePageData.discount)}`}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
              <strong>TOTAL Amount:</strong>
              <span>{`₹ ${formatIndianCurrency(safePageData.grandTotal)}`}</span>
            </div>
          </div>
        </div>

    <Footer safePageData={safePageData} />
    </>
      )}

    </PrintContainer>
  );
};

const MultiPageInvoice = ({ InvoiceData, previewOpen, handlePreviewClose,handlePrint }) => {
  if (!InvoiceData?.productDetails) {
    return (
      <Dialog open={previewOpen} onClose={handlePreviewClose}>
        <DialogContent style={{ padding: 0 }}>
          <p>No invoice data available.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePreviewClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

  const calculateRowHeight = (product) => {
    return 30; // Assuming each row is about 30px tall
};

  const footerHeight = 200;
  const maxPageHeight = 800;
  const maxContentHeight = maxPageHeight - footerHeight;

  const splitIntoPages = (products) => {
    let pages = [];
    let currentPage = [];
    let currentHeight = 0;

    products.forEach((product, index) => {
      const rowHeight = calculateRowHeight(product);
      if (currentHeight + rowHeight > maxContentHeight && currentPage.length > 0) {
        pages.push(currentPage);
        currentPage = [];
        currentHeight = 0;
      }
      currentPage.push({...product, srNo: index + 1});
      currentHeight += rowHeight;
    });

    if (currentPage.length > 0) {
      pages.push(currentPage);
    }

    return pages;
  };

  const productPages = splitIntoPages(InvoiceData.productDetails);
  const pagesData = productPages.map((products) => ({
    ...InvoiceData,
    products,
    storeDetails: Array.isArray(InvoiceData.storeDetails) ? InvoiceData.storeDetails : [InvoiceData.storeDetails],
    

  }));

  return (
    <Dialog 
      open={previewOpen} 
      onClose={handlePreviewClose}
      maxWidth={false}
      PaperProps={{
        sx: {
          minWidth: '100%',
          minHeight: '100%',
          margin: 0,
          maxWidth: 'none',
          '@media print': {
            minWidth: '100%',
            minHeight: '100%',
          }
        }
      }}
    >
      <DialogContent style={{ padding: 0 }}>
        <div id="invoice-content">
          {pagesData.map((pageData, index) => (
            <InvoicePage
              key={index}
              pageData={pageData}
              isLastPage={index === pagesData.length - 1}
            />
          ))}
        </div>
      </DialogContent>
      <NoPrint>
        <DialogActions style={{ padding: '16px' }}>
          <Button 
            onClick={handlePreviewClose}
            variant="contained" 
            style={{ backgroundColor: '#f44336', color: '#fff' }}
          >
            Close
          </Button>
          <Button 
            onClick={handlePrint}
            variant="contained" 
            style={{ backgroundColor: '#4caf50', color: '#fff' }}
            startIcon={<MdPrint />}
          >
            Print
          </Button>
        </DialogActions>
      </NoPrint>
    </Dialog>
  );
};

export default MultiPageInvoice;