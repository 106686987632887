import React from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { MdPrint } from 'react-icons/md';
import { styled } from '@mui/system';
import fl from '../../assets/fl copy.png';

// Hide elements during print
const NoPrint = styled('div')({
    '@media print': {
        display: 'none !important',
    },
});

const formatIndianCurrency = (x) => {
    if (x === undefined || x === null) return '0.00';
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const InvoicePage = ({ pageData, isLastPage }) => {
    const safePageData = pageData || {};
    console.log('InvoicePage - safePageData:', safePageData);   
    
    const safeStoreDetails = safePageData.storeDetails && safePageData.storeDetails[0] 
        ? safePageData.storeDetails[0] 
        : {};
    console.log('InvoicePage - safeStoreDetails:', safeStoreDetails);
    
    const safeProducts = safePageData.products || [];

    const Watermark = styled('div')({
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(-45deg)',
        fontSize: '100px',
        opacity: 0.1,
        pointerEvents: 'none',
        zIndex: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media print': {
            position: 'fixed',
            opacity: 0.05,
        },
    });

    const Footer = ({ safePageData }) => (
        <footer style={{ marginTop: 'auto', paddingTop: '20px' }}>
            <div style={{ marginBottom: '20px' }}>
                <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>Terms & Conditions :</div>
                <ul style={{ margin: 0, paddingLeft: '20px' }}>
                    <li>All sales are final.</li>
                    <li>No exchanges or refunds will be accepted.</li>
                </ul>
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px', fontStyle: 'italic', borderTop: "1px solid #ddd", paddingTop: '10px' }}>
                <h3>Thank you for your Business</h3>
            </div>
        </footer>
    );

    return (
        <div style={{
            fontFamily: 'Montserrat, sans-serif',
            position: 'relative',
            maxWidth: '100%',
            overflow: 'hidden',
            margin: 0,
            padding: '0px 30px',
            backgroundColor: '#ffffff',
            pageBreakAfter: isLastPage ? 'auto' : 'always',
            '@media screen': {
                width: '100%',
                minHeight: '100%',
                padding: 0,
                margin: 0,
                backgroundColor: 'white',
            },
            '@media print': {
                width: '100%',
                minHeight: '100%',
                backgroundColor: 'white',
                boxShadow: 'none',
                fontSize: '12pt',
                overflow: 'hidden',
                margin: 0,
                padding: 0,
            },
        }}>
            <Watermark>
                <img src={fl} alt="" style={{ width: '80%', height: 'auto' }} />
            </Watermark>

            <div style={{ fontSize: '20px', marginBottom: '20px', display: 'flex', justifyContent: "center", color: "#888" }}>CASH MEMO</div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>
                    <img src={fl} alt="" style={{ width: '40px', marginRight: '10px' }} />
                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
                        {safeStoreDetails.storeName}
                    </div>
                </div>
            </div>

            <div style={{ textAlign: 'right', display: "flex", justifyContent: "space-between" }}>
                {safePageData?.orderNumber && (
                    <div style={{ fontSize: '14px', color: '#666' }}>Order No. {safePageData.orderNumber}</div>
                )}
                {safePageData?.createdAt && (
                    <div style={{ color: '#666' }}>
                        {new Date(safePageData.createdAt).toLocaleDateString('en-GB')}
                    </div>
                )}
            </div>

            {/* Store and Customer details */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                {/* Store details */}
                <div>
                    <div style={{ fontWeight: 'bold', marginBottom: '2px' }}>Store Details:</div>
                    <div>{safeStoreDetails.storeName}</div>
                    <div>{safeStoreDetails.address}</div>
                    <div>{[safeStoreDetails.state, safeStoreDetails.city, safeStoreDetails.pinCode].filter(Boolean).join(', ')}</div>
                    <div>{safeStoreDetails.mobileNumber}</div>
                </div>
                {/* Customer details */}
                <div style={{ textAlign: 'right' }}>
                    <div style={{ fontWeight: 'bold', marginBottom: '2px' }}>Customer Details:</div>
                    <div>{safePageData.customerName}</div>
                    <div>{safePageData.customerAddress}</div>
                    <div>{[safePageData.city, safePageData.state, safePageData.pinCode].filter(Boolean).join(', ')}</div>
                    <div>{safePageData.customerContact}</div>
                </div>
            </div>

            {/* Product table */}
            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px' }}>
                <thead>
                    <tr style={{ backgroundColor: '#f0f0f0' }}>
                        <th style={{ padding: '5px', textAlign: 'left', borderBottom: '2px solid #ddd' }}>Sr No</th>
                        <th style={{ padding: '5px', textAlign: 'left', borderBottom: '2px solid #ddd' }}>Product Name</th>
                        <th style={{ padding: '5px', textAlign: 'left', borderBottom: '2px solid #ddd' }}>Category</th>
                        <th style={{ padding: '5px', textAlign: 'right', borderBottom: '2px solid #ddd' }}>Rate</th>
                        <th style={{ padding: '5px', textAlign: 'right', borderBottom: '2px solid #ddd' }}>Pieces</th>
                        <th style={{ padding: '5px', textAlign: 'right', borderBottom: '2px solid #ddd' }}>Amount(₹)</th>
                    </tr>
                </thead>
                <tbody>
                    {safeProducts.map((product, idx) => (
                        <tr key={idx}>
                            <td style={{ padding: '5px', borderBottom: '1px solid #ddd' }}>{product.srNo}</td>
                            <td style={{ padding: '5px', borderBottom: '1px solid #ddd' }}>{product.productName}</td>
                            <td style={{ padding: '5px', borderBottom: '1px solid #ddd' }}>{product.categoryName}</td>
                            <td style={{ padding: '5px', textAlign: 'right', borderBottom: '1px solid #ddd' }}>{formatIndianCurrency(product.rate)}</td>
                            <td style={{ padding: '5px', textAlign: 'right', borderBottom: '1px solid #ddd' }}>{product.pieces}</td>
                            <td style={{ padding: '5px', textAlign: 'right', borderBottom: '1px solid #ddd' }}>{formatIndianCurrency(product.amount)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {isLastPage && (
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '30px' }}>
                    <div style={{ width: '250px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
                            <strong>Discount:</strong>
                            <span>{`₹ ${formatIndianCurrency(safePageData.discount)}`}</span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
                            <strong>TOTAL Amount:</strong>
                            <span>{`₹ ${formatIndianCurrency(safePageData.grandTotal)}`}</span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
                            <strong>Advance Payment:</strong>
                            <span>{`₹ ${formatIndianCurrency(safePageData.advancePayment)}`}</span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
                            <strong>Balance:</strong>
                            <span>{`₹ ${formatIndianCurrency(safePageData.balance)}`}</span>
                        </div>
                    </div>
                </div>
            )}

            <Footer safePageData={safePageData} />
        </div>
    );
};

const BlockInvoice = ({ blockData, previewOpen, handlePreviewClose, handlePrint }) => {
    if (!blockData || !blockData.productDetails) {
        return (
            <Dialog open={previewOpen} onClose={handlePreviewClose} sx={{padding:0}}>
                <DialogContent sx={{padding:0}}>
                    <p>No invoice data available.</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePreviewClose}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }

    const calculateRowHeight = (product) => {
        return 30; // Assuming each row is about 30px tall
    };

    const footerHeight = 200; // Adjust based on your footer's actual height
    const maxPageHeight = 800; // Adjust based on your page layout
    const maxContentHeight = maxPageHeight - footerHeight;

    const splitIntoPages = (products) => {
        let pages = [];
        let currentPage = [];
        let currentHeight = 0;

        products.forEach((product, index) => {
            const rowHeight = calculateRowHeight(product);
            if (currentHeight + rowHeight > maxContentHeight && currentPage.length > 0) {
                pages.push(currentPage);
                currentPage = [];
                currentHeight = 0;
            }
            currentPage.push({...product, srNo: index + 1});
            currentHeight += rowHeight;
        });

        if (currentPage.length > 0) {
            pages.push(currentPage);
        }

        return pages;
    };

    const productPages = splitIntoPages(blockData.productDetails);

    const pagesData = productPages.map((products, index) => ({
        ...blockData,
        products: products,
        storeDetails: Array.isArray(blockData.storeDetails) ? blockData.storeDetails : [blockData.storeDetails],
    }));

    return (
        <Dialog
            open={previewOpen}
            onClose={handlePreviewClose}
            PaperProps={{
                sx: {
                    minWidth: '100%',
                    minHeight: '100%',
                    padding: 0,
                    '@media print': {
                        minWidth: '100%',
                        minHeight: '100%',
                        padding: 0  
                    }
                }
            }}
        >
            <DialogContent sx={{padding:0}}>
                {pagesData.map((pageData, index) => (
                    <InvoicePage
                        key={index}
                        pageData={pageData}
                        isLastPage={index === pagesData.length - 1}
                    />
                ))}
            </DialogContent>
            <NoPrint>
                <DialogActions>
                    <Button variant="contained" onClick={handlePreviewClose} sx={{ backgroundColor: '#f44336', color: '#fff' }}>Close</Button>
                    <Button variant="contained" onClick={handlePrint} sx={{ backgroundColor: '#4caf50', color: '#fff' }} startIcon={<MdPrint />}>Print</Button>
                </DialogActions>
            </NoPrint>
        </Dialog>
    );
};

export default BlockInvoice;