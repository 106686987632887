import React from 'react';
import { Typography, Box } from '@mui/material';

const PaymentTotalIndicator = ({ cashAmount, upiAmount, grandTotal }) => {
  const totalPayment = parseFloat(cashAmount || 0) + parseFloat(upiAmount || 0);
  const isMatching = totalPayment === parseFloat(grandTotal);

  const formatIndianCurrency = (x) => {
    if (x === undefined || x === null) return '0.00';
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  
  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Typography 
        color={isMatching ? 'success.main' : 'error.main'} 
        variant="body1"
      >
        Total Payment: ₹{formatIndianCurrency(totalPayment)} 
        {isMatching ? ' (Matches Grand Total)' : ' (Does not match Grand Total)'}
      </Typography>
    </Box>
  );
};

export default PaymentTotalIndicator;