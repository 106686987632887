import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./resetPassword.module.css";
import { Button, Typography, InputAdornment, IconButton } from "@mui/material";
import { ClipLoader } from "react-spinners";
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const apiurl = process.env.REACT_APP_API_URL;

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [password_confirm, setpassword_confirm] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const { user, token } = useParams();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");
        setError("");
        setLoading(true);

        if (password !== password_confirm) {
            setError("Passwords do not match");
            setLoading(false);
            return;
        }
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${apiurl}user/reset-password/${token}`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                  },
                body: JSON.stringify({ password, password_confirm })

            });

            const data = await response.json();

            if (response.ok) {
                setMessage("Password reset successfully. You can now log in with your new password.");
                setTimeout(() => navigate('/'), 3000); // Redirect to login page after 3 seconds
            } else {
                setError(data.error || "An error occurred. Please try again.");
            }
        } catch (error) {
            setError("An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleTogglePasswordVisibility = (field) => {
        if (field === 'password') {
            setShowPassword(!showPassword);
        } else {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <h2 className={styles.text}>Reset Password</h2>
                    <h4 className={styles.subheading}>
                        Enter your new password below.
                    </h4>

                    <div className={styles.inputbox}>
                        <InputAdornment position="start" className={styles.lockIcon}>
                            <LockIcon />
                        </InputAdornment>
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="New Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <InputAdornment position="end" className={styles.eyeIcon}>
                            <IconButton onClick={() => handleTogglePasswordVisibility('password')}>
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </InputAdornment>
                    </div>

                    <div className={styles.inputbox}>
                        <InputAdornment position="start" className={styles.lockIcon}>
                            <LockIcon />
                        </InputAdornment>
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Confirm New Password"
                            value={password_confirm}
                            onChange={(e) => setpassword_confirm(e.target.value)}
                            required
                        />
                        <InputAdornment position="end" className={styles.eyeIcon}>
                            <IconButton onClick={() => handleTogglePasswordVisibility('confirmPassword')}>
                                {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </InputAdornment>
                    </div>

                    {message && <Typography sx={{color:"green",backgroundColor:"#fff"}}>{message}</Typography>}
                    {error && <Typography sx={{color:"red",backgroundColor:"#fff"}}>{error}</Typography>}

                    <Button className={styles.changePassword} type="submit" disabled={loading}>
                        {loading ? <ClipLoader size={20} color={"#ffffff"} /> : "Change Password"}
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;