import React, { useEffect, useState } from "react";
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ClipLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";

import styles from "./login.module.css";
import FlCopy from "../assets/fl copy.png";

const API_URL = process.env.REACT_APP_API_URL;

const Login = () => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        rememberMe: false,
        showPassword: false
    });
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const role_Name = localStorage.getItem('roleName');
        console.log("rolename", role_Name)

        if (token) {
            if (role_Name === 'staff') {
                navigate('/inventory');
            } else {
                navigate('/dashboard');
            }
        }

        // Load saved credentials if remember me was checked
        const savedCredentials = JSON.parse(localStorage.getItem('savedCredentials'));
        if (savedCredentials) {
            setFormData(prevState => ({
                ...prevState,
                email: savedCredentials.email || "",
                password: savedCredentials.password || "",
                rememberMe: true
            }));
        }
    }, [navigate]);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setLoading(true);
    
        if (!validateEmail(formData.email)) {
            setError("Please enter a valid email address.");
            setLoading(false);
            return;
        }
    
        try {
            const response = await fetch(`${API_URL}user/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: formData.email,
                    password: formData.password
                }),
            });
    
            const data = await response.json();
            
            if (data.status === 'success') {
                const { token } = data;
    
                // Decode the JWT token to extract the role
                const tokenParts = token.split('.');
                const decodedPayload = JSON.parse(atob(tokenParts[1])); // Decode the payload part of the token
                const { roleName } = decodedPayload;
    
                console.log("Decoded Payload:", decodedPayload); // Log the entire decoded payload
                console.log("Extracted Role Name:", roleName); // Log the extracted roleName
    
                // Save the token and role in localStorage
                localStorage.setItem('token', token);
                localStorage.setItem('roleName', roleName); // Store the roleName directly from the token
    
                if (formData.rememberMe) {
                    localStorage.setItem('savedCredentials', JSON.stringify({
                        email: formData.email,
                        password: formData.password
                    }));
                } else {
                    localStorage.removeItem('savedCredentials');
                }
    
                // Navigate based on the extracted roleName
                if (roleName === 'staff') {
                    navigate('/inventory');
                } else {
                    navigate('/dashboard');
                }
            } else {
                setError(data.message || "An error occurred. Please try again.");
            }
        } catch (error) {
            console.error("Error during login:", error);
            setError("An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };
    


    const togglePasswordVisibility = () => {
        setFormData(prevState => ({
            ...prevState,
            showPassword: !prevState.showPassword
        }));
    };

    return (
        <div className={styles.container}>
            <form className={styles.form} onSubmit={handleSubmit}>
                <img src={FlCopy} alt="Logo" className={styles.logo} />
                <h1 className={styles.subheading}>Login to Your Account</h1>
                {error && <div className={styles.error}>{error}</div>}
                <div className={styles.inputbox}>
                    <EmailRoundedIcon className={styles.icon} />
                    <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        required
                        value={formData.email}
                        onChange={handleChange}
                    />
                </div>
                <div className={styles.inputbox}>
                    <LockIcon className={styles.icon} />
                    <input
                        type={formData.showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        required
                        value={formData.password}
                        onChange={handleChange}
                    />
                    <span
                        onClick={togglePasswordVisibility}
                        className={styles.visibilityicon}
                    >
                        {formData.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </span>
                </div>
                <div className={styles.rememberforget}>
                    <label>
                        <input
                            type="checkbox"
                            name="rememberMe"
                            checked={formData.rememberMe}
                            onChange={handleChange}
                        />
                        Remember me
                    </label>
                    <Link className={styles.forgetcolor} to="/forgot">
                        Forgot Password?
                    </Link>
                </div>
                <button className={styles.loginButton} type="submit" disabled={loading}>
                    {loading ? <ClipLoader size={20} color={"#ffffff"} /> : "Login"}
                </button>
            </form>
            <div className={styles.textlast}>
                <h2>
                    <a
                        href="https://www.mobisoftseo.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                    >
                        Developed by MobisoftSeo Technologies
                    </a>
                </h2>
            </div>
        </div>
    );
};

export default Login;