import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from '../login/login';
import ForgotPassword from '../forgotpassword/forgot';
import MiniDrawer from '../home/Navbar/navbar';
import ResetPassword from '../forgotpassword/resetPassword';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem('token');
  return isAuthenticated ? children : <Navigate to="/" replace />;
};

const AppRoutes = () => {
  // const isAuthenticated = !!localStorage.getItem('token');
  const user = localStorage.getItem('user');

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Login />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/resetPassword" element={<ResetPassword />} />

        {/* Protected Route for Dashboard */}
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <MiniDrawer user={user} />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default AppRoutes;