import Swal from 'sweetalert2';

/**
 * Shows a delete confirmation alert.
 * @param {Function} onConfirm - The callback function to execute when the user confirms the delete action.
 * @param {Event} [event] - The event object from the click event (optional).
 */
export const showDeleteConfirmation = (onConfirm, objectId, event) => {
    let swalConfig = {
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      target: document.querySelector('.MuiDialog-container'),
      customClass: {
        container: 'my-swal-container'
      }
    };
  
    if (event && event.currentTarget) {
      const buttonRect = event.currentTarget.getBoundingClientRect();
      swalConfig.didOpen = () => {
        const swalContainer = document.querySelector('.my-swal-container');
        if (swalContainer) {
          swalContainer.style.position = 'absolute';
          swalContainer.style.left = `${buttonRect.left}px`;
          swalContainer.style.top = `${buttonRect.top}px`;
        }
      };
    }
  
    Swal.fire(swalConfig).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await onConfirm(objectId);
          
          Swal.fire({
            title: 'Deleted!',
            text: 'The product has been removed from the invoice.',
            icon: 'success',
            target: document.querySelector('.MuiDialog-container'),
            customClass: {
              container: 'my-swal-container'
            }
          });
        } catch (error) {
          Swal.fire({
            title: 'Error!',
            text: error.message || 'An error occurred while deleting the product.',
            icon: 'error',
            target: document.querySelector('.MuiDialog-container'),
            customClass: {
              container: 'my-swal-container'
            }
          });
        }
      }
    });
  };