import React, { useReducer, useState, useEffect, } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import styles from './sales.module.css'
import AddIcon from '@mui/icons-material/Add';
import DatePickerIcon from '@mui/icons-material/DateRange';
import { MdSearch } from 'react-icons/md';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import PaymentTotalIndicator from './PaymentTotalIndicator';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import LoadingSpinner from './loaderSpinner';

const apiurl = process.env.REACT_APP_API_URL;

const initialState = {
    formValues: {
        salesInvoiceId: '',
        storeId: '',
        storeName: '',
        customerName: '',
        contactNo: '',
        customerAddress: '',
        city: '',
        state: " ",
        pincode: '',
        amount: '',
        cashAmount: '',
        upiAmount: '',
        date: '',
        extraAmountAdd: '',
        discount: '',
        grandTotal: '',
    },
    errors: {},
    isLoading: false,
    searchError: null

};

const formReducer = (state, action) => {
    switch (action.type) {
        case 'SET_FORM_VALUES':
            return { ...state, formValues: { ...state.formValues, ...action.payload } };
        case 'SET_ERRORS':
            return { ...state, errors: { ...state.errors, ...action.payload } };
        case 'RESET_FORM':
            return {
                ...state,
                formValues: { ...initialState.formValues, storeName: '', productName: '' },
                errors: {}
            };
        case 'SET_LOADING':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

const SalesReturn = () => {
    const [state, dispatch] = useReducer(formReducer, initialState);
    const [showForm, setShowForm] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [othersAmount, setOthersAmount] = useState('');
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [productsData, setProductsData] = useState([]);
    const [fieldErrors, setFieldErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [userRole, setUserRole] = useState('');
    const [userStore, setUserStore] = useState(null);
    const [isReturnCompleted, setIsReturnCompleted] = useState(false);


    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${apiurl}user/loggeduser`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setUserRole(data.user.roleName.toLowerCase());
                if (data.user.roleName.toLowerCase() === 'store') {
                    setUserStore({
                        id: data.user.storeId,
                        storeName: data.user.storeName
                    });
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchUserData();
    }, []);

    const [products, setProducts] = useState([{
        id: '',
        productName: '',
        category: '',
        sellRate: '',
        pieces: '',
        returnPieces: '',
        returnAmount: '',
        amount: ''
    }]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;


        if (name === 'date') {
            dispatch({ type: 'SET_FORM_VALUES', payload: { [name]: value } });
        } else {
            dispatch({ type: 'SET_FORM_VALUES', payload: { [name]: value } });
        }
        dispatch({ type: 'SET_ERRORS', payload: { [name]: '' } });
    };

    const handleProductChange = (index, field, value) => {
        if (['rate', 'pieces', 'returnPieces', 'unit'].includes(field)) {
            if (!/^\d*$/.test(value)) {
                return;
            }
        }
    
        const newProducts = [...products];
    
        if (field === 'productName') {
            const selectedProduct = productsData.find(p => p.id === value);
            if (selectedProduct) {
                newProducts[index] = {
                    ...newProducts[index],
                    id: selectedProduct.id,
                    productName: selectedProduct.productName,
                    category: selectedProduct.category,
                };
            }
        } else if (field === 'returnPieces') {
            const originalPieces = parseFloat(newProducts[index].pieces) || 0;
            const returnPieces = parseFloat(value) || 0;
            const rate = parseFloat(newProducts[index].rate) || 0;
    
            // Update the field errors state
            const newFieldErrors = { ...fieldErrors };
            if (returnPieces > originalPieces) {
                newFieldErrors[`returnPieces-${index}`] = `Return pieces cannot be more than the original ${originalPieces} pieces.`;
            } else {
                delete newFieldErrors[`returnPieces-${index}`];
            }
            setFieldErrors(newFieldErrors);
    
            // Calculate return amount and remaining amount
            const returnAmount = rate * returnPieces; // Calculate return amount based on rate and return pieces
            const remainingPieces = originalPieces - returnPieces;
            const remainingAmount = rate * remainingPieces;
    
            newProducts[index] = {
                ...newProducts[index],
                returnPieces: value,
                returnAmount: returnAmount.toFixed(2),
                amount: remainingAmount.toFixed(2)
            };
        } else {
            newProducts[index][field] = value;
        }
    
        setProducts(newProducts);
    };
    const calculateTotals = () => {
        const subtotal = products.reduce((sum, product) => {
            const amount = parseFloat(product.amount || 0);
            return sum + amount;
        }, 0);

        const totalReturn = products.reduce((sum, product) => {
            const returnAmount = parseFloat(product.returnAmount || 0);
            return sum + returnAmount;
        }, 0);

        const extraAmountAddValue = parseFloat(othersAmount) || 0;
        const discountValue = parseFloat(totalDiscount) || 0;
        const subtotalWithExtras = subtotal + extraAmountAddValue;
        const grandTotal = Math.max(subtotalWithExtras - discountValue, 0).toFixed(2);

        return {
            subtotal: subtotal.toFixed(2),
            totalReturn: totalReturn.toFixed(2),
            extraAmountAdd: extraAmountAddValue.toFixed(2),
            grandTotal: grandTotal
        };
    };



    const handleQueryChange = (e) => {
        dispatch({
            type: 'SET_FORM_VALUES',
            payload: { salesInvoiceId: e.target.value }
        });
    };

    const handleSearch = async () => {
        if (!state.formValues.salesInvoiceId) {
            setSnackbarOpen(true);
            setSnackbarMessage('Please enter a memo number');
            setSnackbarSeverity('error');
            return;
        }

        try {
            // Reset all error states and form state before new search
            dispatch({ type: 'SET_LOADING', payload: true });
            setFieldErrors({}); // Reset field errors
            dispatch({ type: 'SET_ERRORS', payload: {} }); // Reset form errors

            // Reset products to initial state
            setProducts([{
                id: '',
                productName: '',
                category: '',
                sellRate: '',
                pieces: '',
                returnPieces: '',
                returnAmount: '',
                amount: ''
            }]);

            // Reset other states
            setOthersAmount('');
            setTotalDiscount(0);

            const salesId = state.formValues.salesInvoiceId;
            const response = await fetch(`${apiurl}return/${salesId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                let errorMessage;
                if (response.status === 404) {
                    errorMessage = 'Memo number not found';
                } else {
                    errorMessage = 'Error fetching Memo number not found';
                }
                throw new Error(errorMessage);
            }

            const data = await response.json();

            // Add store validation
            if (userRole === 'store' && userStore && data.invoice.storeId !== userStore.id) {
                throw new Error('This memo number does not belong to your store');
            }

            // Extract invoice data from the response
            const invoice = data.invoice;

            // Reset form values before updating with new data
            dispatch({
                type: 'RESET_FORM'
            });

            // Update form values with the received data
            dispatch({
                type: 'SET_FORM_VALUES',
                payload: {
                    salesInvoiceId: salesId, // Keep the searched ID
                    storeId: invoice.storeId || '',
                    storeName: invoice.storeName || '',
                    customerName: invoice.customerName || '',
                    contactNo: invoice.contactNo || '',
                    customerAddress: invoice.customerAddress || '',
                    city: invoice.city || '',
                    state: invoice.state || '',
                    pincode: invoice.pinCode || '',
                    amount: invoice.grandTotal || '',
                    cashAmount: invoice.cashAmount || '0',
                    upiAmount: invoice.upiAmount !== undefined ? invoice.upiAmount.toString() : '0',
                    date: invoice.date ? invoice.date.split('T')[0] : '',
                    extraAmountAdd: invoice.extraAmountAdd || '',
                    discount: invoice.discount || '',
                    grandTotal: invoice.grandTotal || ''
                }
            });

            // Update products state with product details from the response
            if (invoice.productDetails && Array.isArray(invoice.productDetails)) {
                const mappedProducts = invoice.productDetails.map(product => ({
                    id: product.productId || '',
                    productName: product.productName || '',
                    category: product.categoryName || '',
                    rate: product.rate || '',
                    pieces: product.pieces !== undefined ? product.pieces.toString() : '0',
                    returnPieces: '', // Initialize return pieces as empty
                    amount: product.amount || ''
                }));
                setProducts(mappedProducts);
            }

            // Show the form
            setShowForm(true);

            // Clear any previous errors
            dispatch({ type: 'SET_SEARCH_ERROR', payload: null });

            // Reset snackbar if it was showing an error
            if (snackbarOpen && snackbarSeverity === 'error') {
                setSnackbarOpen(false);
            }

        } catch (error) {
            console.error('Error fetching sales invoice:', error);
            setSnackbarOpen(true);
            setSnackbarMessage(error.message);
            setSnackbarSeverity('error');

            // Reset form on error
            setShowForm(false);
            dispatch({ type: 'RESET_FORM' });
            setProducts([{
                id: '',
                productName: '',
                category: '',
                sellRate: '',
                pieces: '',
                returnPieces: '',
                amount: ''
            }]);
        } finally {
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    };
    // Add console log to confirm state changes
    useEffect(() => {
        console.log('showForm state:', showForm);
    }, [showForm]);

    const handleReturnSale = async () => {
        const hasInvalidReturnPieces = products.some((product) => {
            const originalPieces = parseFloat(product.pieces) || 0;
            const returnPieces = parseFloat(product.returnPieces) || 0;
            return returnPieces > originalPieces;
        });

        if (hasInvalidReturnPieces) {
            setSnackbarOpen(true);
            setSnackbarMessage('Return pieces cannot greater than pieces for any product');
            setSnackbarSeverity('error');
            return;
        }

        try {
            dispatch({ type: 'SET_LOADING', payload: true }); // Set loading to true

            const requestBody = {
                salesInvoiceId: state.formValues.salesInvoiceId,
                storeId: state.formValues.storeId,
                cashAmount: parseFloat(state.formValues.cashAmount) || 0,
                upiAmount: parseFloat(state.formValues.upiAmount) || 0,
                products: products.map((product) => ({
                    id: product.id,
                    returnPieces: parseFloat(product.returnPieces) || 0,
                    returnAmount: parseFloat(product.returnAmount) || 0,
                })),
                grandTotal: parseFloat(calculateTotals().grandTotal) || 0,
                totalReturn: parseFloat(calculateTotals().totalReturn) || 0
            };

            const response = await fetch(`${apiurl}return`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }


            const data = await response.json();
            console.log('Sales Return saved:', data);

            // Set return completed flag
            setIsReturnCompleted(true);

            // Show success message
            setSnackbarOpen(true);
            setSnackbarMessage('Sales return successful!');
            setSnackbarSeverity('success');

            // Clear any field errors but keep the form data
            setFieldErrors({});

            const updatedProducts = products.map(product => ({
                ...product,
                isReturned: true  // Add a flag to indicate this product has been returned
            }));
            setProducts(updatedProducts);

        } catch (error) {
            console.error('Error Saving Sales Return:', error);
            setSnackbarOpen(true);
            setSnackbarMessage('Product not found');
            setSnackbarSeverity('error');
        } finally {
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    };

    const resetForm = () => {
        dispatch({ type: 'RESET_FORM' });
        setProducts([{
            id: '',
            productName: '',
            category: '',
            sellRate: '',
            pieces: '',
            returnPieces: '',
            amount: ''
        }]);
        setOthersAmount('');
        setTotalDiscount(0);
        setFieldErrors({}); // Reset field errors
        setSnackbarOpen(false); // Reset snackbar
        setIsReturnCompleted(false); // Reset the return completed state

    };

    const formatIndianCurrency = (x) => {
        if (x === undefined || x === null) return '0.00';
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // Update the cancel button click handler
    const handleCancel = () => {
        resetForm();
        setShowForm(false);
        setEditIndex(null);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className={styles.invoicecontentContainer}>
            {state.isLoading && <LoadingSpinner />}
            <div className={styles.formHeader}>
                <h4 className={styles.invoiceheader}>Return Sales</h4>
            </div>
            <div className={styles.searchhead}>
                <Grid item>
                    <TextField
                        name="salesInvoiceId"
                        label="Search by MemoNo"
                        value={state.formValues.salesInvoiceId}
                        onChange={handleQueryChange}
                        error={!!state.searchError}
                        onKeyPress={handleKeyPress}  // Add this line
                        helperText={state.searchError}
                        InputProps={{
                            className: styles.invoiceinput,
                        }}
                        InputLabelProps={{
                            className: styles.invoicelabel,
                            shrink: true
                        }}
                    />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#009999',
                            color: '#fff',
                            textTransform: 'capitalize',
                            '&:hover': {
                                backgroundColor: '#01dcb8',
                            },
                        }}
                        startIcon={<MdSearch />}
                        onClick={handleSearch}
                        disabled={state.isLoading}

                    >
                        {state.isLoading ? 'Searching...' : 'Search'}

                    </Button>
                </Grid>
            </div>
            <div>
                {showForm && (
                    <form component="form" noValidate autoComplete="off" className={styles.formContainer}>
                        {state.isLoading && <LoadingSpinner />}

                        <div className={styles.formContent}>

                            <Grid container spacing={2}>

                                <Grid item xs={2}>
                                    <TextField
                                        name="storeName"
                                        label="Store Name"
                                        value={state.formValues.storeName || ''}
                                        disabled
                                        fullWidth
                                        error={!!state.errors.storeName}
                                        helperText={state.errors.storeName}
                                        InputProps={{
                                            className: styles.invoiceinput,
                                        }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '-6px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                                '& .MuiFormLabel-asterisk': {
                                                    color: 'red',
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000',
                                            },
                                        }}
                                    />
                                </Grid>


                                <Grid item xs={2}>
                                    <TextField
                                        name="contactNo"
                                        label="Contact No"
                                        value={state.formValues.contactNo || ''}
                                        disabled
                                        fullWidth
                                        error={!!state.errors.contactNo}
                                        helperText={state.errors.contactNo}
                                        InputProps={{
                                            className: styles.invoiceinput,
                                        }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '-6px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                                '& .MuiFormLabel-asterisk': {
                                                    color: 'red',
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        name="state"
                                        value={state.formValues.state}
                                        onChange={handleInputChange}
                                        label="State"
                                        InputProps={{ className: styles.invoiceinput }}
                                        disabled
                                        fullWidth
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '-6px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        name="city"
                                        label="City"
                                        value={state.formValues.city}
                                        onChange={handleInputChange}
                                        fullWidth
                                        InputProps={{ className: styles.invoiceinput }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '-6px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000',
                                            },
                                        }}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        name="pinCode"
                                        label="Pincode"
                                        value={state.formValues.pinCode}
                                        onChange={handleInputChange}
                                        fullWidth
                                        inputProps={{
                                            pattern: "[0-9]{6}",
                                            inputMode: "numeric",
                                            maxLength: 6,
                                        }}
                                        InputProps={{ className: styles.invoiceinput }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '-6px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000',
                                            },
                                        }}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <TextField
                                        name="date"
                                        type="date"
                                        value={state.formValues.date}
                                        onChange={handleInputChange}
                                        fullWidth
                                        InputProps={{ className: styles.invoiceinput }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '-6px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000', // Adjust text color if needed
                                            },
                                        }}
                                        InputAdornment={{ endAdornment: <DatePickerIcon className={styles.datePickerIcon} /> }}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <TextField
                                        name="cashAmount"
                                        label="Enter Cash Amount"
                                        value={formatIndianCurrency(state.formValues.cashAmount) || ''}
                                        required={{ color: "red" }}
                                        error={!!state.errors.cashAmount}
                                        helper={!!state.errors.cashAmount}
                                        onChange={(e) => {
                                            const value = e.target.value.replace(/,/g, ''); // Remove commas
                                            if (value === '' || /^[0-9]{0,11}$/.test(value)) { // Allow up to 11 digits
                                                handleInputChange({
                                                    target: {
                                                        name: 'cashAmount',
                                                        value: value
                                                    }
                                                });
                                            }
                                        }}
                                        fullWidth
                                        InputProps={{ className: styles.invoiceinput }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '-6px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                                // Hide the default asterisk and apply custom styles
                                                '& .MuiFormLabel-asterisk': {
                                                    color: 'red', // Customize the color of the asterisk
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000', // Text color
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <TextField
                                        name="upiAmount"
                                        label="Enter UPI Amount"
                                        value={formatIndianCurrency(state.formValues.upiAmount)}
                                        required={{ color: "red" }}
                                        error={!!state.errors.upiAmount}
                                        helper={!!state.errors.upiAmount}
                                        onChange={(e) => {
                                            const value = e.target.value.replace(/,/g, ''); // Remove commas
                                            if (value === '' || /^[0-9]{0,11}$/.test(value)) { // Allow up to 11 digits
                                                handleInputChange({
                                                    target: {
                                                        name: 'upiAmount',
                                                        value: value
                                                    }
                                                });
                                            }
                                        }}
                                        fullWidth
                                        InputProps={{ className: styles.invoiceinput }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                top: '-6px',
                                                color: '#555',
                                                '&.Mui-focused': {
                                                    color: '#009999',
                                                },
                                                // Hide the default asterisk and apply custom styles
                                                '& .MuiFormLabel-asterisk': {
                                                    color: 'red', // Customize the color of the asterisk
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                color: '#000', // Text color
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <div className={styles.ScrollTable}>
                                <table className={styles.invoicetabledata} >
                                    <thead className={styles.tableHeader}>
                                        <tr>
                                            <th className={styles.invoiceTh}>Sr.No</th>
                                            <th className={styles.invoiceTh}>Select Product</th>
                                            <th className={styles.invoiceTh}>Category</th>
                                            <th className={styles.invoiceTh}>Rate</th>
                                            <th className={styles.invoiceTh}>Pieces</th>
                                            <th className={styles.invoiceTh}>Return Pieces</th>
                                            <th className={styles.invoiceTh}>Return Pieces Amount</th>
                                            <th className={styles.invoiceTh}>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products.map((product, index) => {
                                            const hasError = !!fieldErrors[`returnPieces-${index}`];

                                            return (
                                                <tr key={index}>
                                                    <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ddd' }}>
                                                        {index + 1}
                                                    </td>
                                                    <td className={styles.invoiceTd}>
                                                        <input
                                                            type="text"
                                                            value={product.productName || ''}
                                                            disabled
                                                            className={styles.invoiceinputs}
                                                        />
                                                    </td>

                                                    <td className={styles.invoiceTd}>
                                                        <input
                                                            type="text"
                                                            value={product.category || ''}
                                                            disabled
                                                            className={styles.invoiceinputs}

                                                        />
                                                    </td>
                                                    <td className={styles.invoiceTd}>
                                                        <input
                                                            type="text"
                                                            value={formatIndianCurrency(product.rate)}
                                                            onChange={(e) => {
                                                                const value = e.target.value.replace(/,/g, ''); // Remove commas if any

                                                                // Allow only numbers and ensure it doesn't exceed 11 digits
                                                                if (value === '' || /^\d{1,11}(\.\d{0,2})?$/.test(value)) {
                                                                    handleProductChange(index, 'rate', value);
                                                                }
                                                            }}
                                                            className={styles.invoiceinputs}
                                                            style={{ textAlign: 'right' }}
                                                            disabled
                                                        />
                                                    </td>
                                                    <td className={styles.invoiceTd}>
                                                        <input
                                                            value={product.pieces || '0'}
                                                            onChange={(e) => handleProductChange(index, 'pieces', e.target.value)}
                                                            className={styles.invoiceinputs}
                                                            style={{ textAlign: 'right' }}
                                                            disabled
                                                        />
                                                    </td>
                                                    <td className={styles.invoiceTd}>
                                                        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                                            <input
                                                                value={product.returnPieces}
                                                                onChange={(e) => handleProductChange(index, 'returnPieces', e.target.value)}
                                                                className={`${styles.invoiceinputs} ${hasError ? styles.errorInput : ''}`}
                                                                style={{
                                                                    textAlign: 'right',
                                                                    borderColor: hasError ? 'red' : 'inherit',
                                                                    backgroundColor: product.isReturned ? '#f0f0f0' : 'white' // Gray out returned items
                                                                }}
                                                                disabled={product.isReturned} // Disable input if already returned

                                                            />
                                                            {hasError && (
                                                                <Tooltip title={fieldErrors[`returnPieces-${index}`]} placement="top">
                                                                    <InfoIcon
                                                                        style={{
                                                                            color: 'red',
                                                                            marginLeft: '4px',
                                                                            cursor: 'pointer',
                                                                            fontSize: '20px',
                                                                            animation: 'pulse 2s infinite',
                                                                            transition: 'transform 0.2s ease-in-out',
                                                                            '&:hover': {
                                                                                transform: 'scale(1.1)'
                                                                            }
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className={styles.invoiceTd}>
                                                        <input
                                                            type="text"
                                                            value={formatIndianCurrency(product.returnAmount)}
                                                            disabled
                                                            className={styles.invoiceinputs}
                                                            style={{ textAlign: 'right' }}
                                                        />
                                                    </td>
                                                    <td className={styles.invoiceTd}>
                                                        <input
                                                            type="text"
                                                            value={formatIndianCurrency(product.amount)}
                                                            disabled
                                                            className={styles.invoiceinputs}
                                                            style={{ textAlign: 'right' }}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                                <div className={styles.summary}>
                                    <div>Sub Total: <span>₹{formatIndianCurrency(calculateTotals().subtotal)}</span></div>
                                    <div>Extra Amount: <span>₹{formatIndianCurrency(calculateTotals().extraAmountAdd)}</span></div>
                                    <div>
                                        Total Discount:
                                        <input
                                            value={formatIndianCurrency(totalDiscount)}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/,/g, '');
                                                if (value === '' || /^[0-9]{0,11}$/.test(value)) {
                                                    setTotalDiscount(parseFloat(value) || 0);
                                                }
                                            }}
                                            className={styles.invoiceinputs}
                                            style={{ width: '80px', marginLeft: '10px', textAlign: 'right' }}
                                            disabled={showForm}
                                        />
                                    </div>
                                    <div>Total Return Amount: <span>₹{formatIndianCurrency(calculateTotals().totalReturn)}</span></div>
                                    <div>Grand Total: <span>₹{formatIndianCurrency(calculateTotals().grandTotal)}</span></div>
                                    <PaymentTotalIndicator
                                        cashAmount={state.formValues.cashAmount}
                                        upiAmount={state.formValues.upiAmount}
                                        grandTotal={calculateTotals().grandTotal}

                                    />
                                </div>
                                <div sx={{ mt: 2, justifyContent: "flex-start" }}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            mr: 2, // Add margin-right
                                            backgroundColor: '#009999',
                                            color: '#fff',
                                            textTransform: 'capitalize',
                                            '&:hover': {
                                                backgroundColor: '#01dcb8',
                                            },
                                            '&:disabled': {
                                                backgroundColor: '#cccccc',
                                                color: '#666666'
                                            }
                                        }}
                                        onClick={handleReturnSale}
                                        startIcon={<AddIcon />}
                                        disabled={isReturnCompleted} // Disable button when return is completed

                                    >
                                        Return Sales
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#e32e2e',
                                            color: '#fff',
                                            textTransform: 'capitalize',
                                            '&:hover': {
                                                backgroundColor: '#ff0000',
                                            },
                                        }}
                                        onClick={handleCancel}

                                        startIcon={<CloseIcon />}
                                    >
                                        Cancel
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </form>
                )}
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    variant="filled"
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>

    );
};

export default SalesReturn;