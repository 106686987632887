import React, { useState, useEffect, useReducer } from 'react';
import {
  Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar, Box,
  Grid, Select, MenuItem, FormControl, InputLabel,InputAdornment,
  FormHelperText
} from '@mui/material';

import { MdSearch, } from 'react-icons/md';
import styles from './staff.module.css';
import Pagination from '@mui/lab/Pagination';
import { Alert } from '@mui/lab';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import { Checkbox, ListItemText } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import { Delete, Edit } from '@mui/icons-material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';



const apiurl = process.env.REACT_APP_API_URL;
const initialState = {
  formValues: {
    id: '',
    firstName: '',
    lastName: '',
    contact: '',
    gender: '',
    // photo: '',
    dob: '',
    email: '',
    password: '',
    address1: '',
    address2: '',
    state: '',
    city: '',
    pinCode: '',
    role: {
      roleId: '',

    },
    storeNames: [],

    createdAt: '',
  },
  errors: {},
};

const formReducer = (state, action) => {
  // console.log("state data", action.payload)
  switch (action.type) {
    case 'SET_FORM_VALUES':
      return {
        ...state,
        formValues: {
          ...state.formValues, ...action.payload
        },

      };
    case 'SET_ERRORS':
      return {
        ...state,
        errors: { ...state.errors, ...action.payload },
      };
    case 'RESET_FORM':
      return {
        ...state,
        formValues: initialState.formValues,
        errors: {},
      };
    default:
      return state;
  }
};

const Staff = () => {
  const [staffList, setStaffList] = useState([]);
  const [staffId, setStaffId] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [roles, setRoles] = useState([]);
  const [stores, setStores] = useState([]);
  const [states, setStates] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
 

  const [staff, setStaff] = useState([{
    id: '',
    firstName: '',
    lastName: '',
    contact: '',
    gender: '',
    // photo: '',
    dob: '',
    email: '',
    password: '',
    address1: '',
    address2: '',
    state: '',
    city: '',
    pinCode: '',
    storeNames: ''
  }]);


  //=====================loading=====================================================
  const LoadingOverlay = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex:9999
    }}>
      <CircularProgress />
    </div>
  );
  //================================alert =====================================================================================
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });

  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
    setTimeout(() => setAlertInfo({ open: false, message: '', severity: 'success' }), 5000); // Close after 5 seconds
  };

  //====================================================================================

  const tableCellStyle = {
    padding: '4px',  // Ensure no extra padding affects height
    textAlign: 'center',
    fontSize: '13px',
    border: '1px solid #333'
  };

  //-------------------------------------------fetch api--------------------------------------------------------------------------------------------------
  const [query, setQuery] = useState('');

  const fetchStaffs = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);

    if (!token) {
      showAlert('No token found', 'error');
      return;
    }

    try {
      let url = new URL(`${apiurl}staff`);

      // Add parameters to the URL
      url.searchParams.append('page', currentPage);
      url.searchParams.append('limit', rowsPerPage);

      if (fromDate) {
        url.searchParams.append('fromDate', fromDate);
      }
      if (toDate) {
        url.searchParams.append('toDate', toDate);
      }
      if (query) {
        url.searchParams.append('query', query);
      }

      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch staffs');
      }

      const data = await response.json();

      setStaffList(data.staff);
      setTotalPages(data.numberOfPages || 1);

    } catch (error) {
      console.error('Error fetching staff:', error);
      // showAlert(error.message || 'Failed to fetch staffs', 'error');
      setStaffList([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    fetchStaffs();
    // setQuery(''); // Reset the search input after searching
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to first page when changing rows per page
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const commonTextFieldStyles = {
    '& .MuiOutlinedInput-root': {
      height: '40px',
      '& fieldset': {
        borderColor: '#888',
      },
      '&:hover fieldset': {
        borderColor: '#555',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#009999',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#555',
      '&.Mui-focused': {
        color: '#009999',
      },
    },
    '& .MuiInputBase-input': {
      color: '#333',
    },
  };

  useEffect(() => {
    fetchStaffs();
  }, [currentPage, rowsPerPage,]);



  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "storeName") {
      const selectedStoreIds = Array.isArray(value) ? value.map(item => parseInt(item.split('|')[1])) : [];
      dispatch({
        type: 'SET_FORM_VALUES',
        payload: {
          storeNames: selectedStoreIds
        }
      });
    } else {
      // Handle other inputs
      let trimmedValue = value;
      if (typeof value === 'string') {
        trimmedValue = value.trim();
      }

      if (name === "contact") {
        const isNumeric = /^\d*$/.test(trimmedValue);
        if (!isNumeric) return;
      }
      dispatch({ type: 'SET_FORM_VALUES', payload: { [name]: trimmedValue } });
    }
    dispatch({ type: 'SET_ERRORS', payload: { [name]: '' } });
  };


  const handleDateChange = (date) => {
    dispatch({
      type: 'SET_FORM_VALUES',
      payload: {
        dob: date ? moment(date).format('YYYY-MM-DD') : null
      }
    });
  };

  const validateForm = () => {
    const { firstName, lastName, contact, email, gender, password, roleId, storeNames } = state.formValues;
    const newErrors = {};
    if (!firstName) newErrors.firstName = 'First Name is required';
    if (!lastName) newErrors.lastName = 'Last Name is required';
    if (!contact) newErrors.contact = 'Contact is required';
    if (!email) newErrors.email = 'Email is required';
    if (!password) newErrors.password = 'Password is required';
    if (!roleId) newErrors.roleId = 'role is required';
    if (!gender) newErrors.gender = 'gender is required';
    if (!storeNames) newErrors.storeNames = 'store Name is required';


    dispatch({ type: 'SET_ERRORS', payload: newErrors });
    return Object.keys(newErrors).length === 0;
  };

  const handleAddOrUpdateStaff = async (e) => {
    const token = localStorage.getItem('token');
    setIsLoading(true);

    e.preventDefault();
    const isValid = validateForm();

    if (!isValid) {
      setErrorSnackbarOpen(true);
      setIsLoading(false);
      return;
    }

    // Close the dialog immediately
    setShowForm(false);
    // Create a JSON object from form values
    const staffData = {
      firstName: state.formValues.firstName,
      lastName: state.formValues.lastName,
      contact: state.formValues.contact,
      gender: state.formValues.gender,
      dob: state.formValues.dob,
      email: state.formValues.email,
      // password: state.formValues.password,
      address1: state.formValues.address1,
      address2: state.formValues.address2,
      state: state.formValues.state,
      city: state.formValues.city,
      pinCode: state.formValues.pinCode,
      role: parseInt(state.formValues.roleId),
      storeNames: Array.isArray(state.formValues.storeNames) ? state.formValues.storeNames : []
    };

    if (editIndex === null) {
      staffData.password = state.formValues.password;
    }
    try {
      let response;
      if (editIndex !== null) {
        response = await fetch(`${apiurl}staff/${staffId}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(staffData),
        });
      } else {
        response = await fetch(`${apiurl}staff`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(staffData),
        });
      }


      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to add/update staff: ${errorData.message || response.statusText}`);
      }

      const newStaffData = await response.json();
      // console.log('Staff added/updated successfully', newStaffData);

      setStaffList(prevList => {
        if (editIndex !== null) {
          // Update existing staff
          showAlert('Staff updated successfully', 'success');
          return prevList.map((staff, index) =>
            index === editIndex ? { ...staff, ...newStaffData } : staff
          );
        } else {
          // Add new staff
          showAlert('Staff added successfully', 'success');
          return [newStaffData, ...prevList];
        }
      });

      // console.log('Staff added/updated successfully');
      dispatch({ type: 'RESET_FORM' });
      setShowForm(false);
      setEditIndex(null);
      setStaffId(null);  // Reset staffId after update


      fetchStaffs();

    } catch (error) {
      console.error('Error adding/updating staff:', error);
      showAlert(error.message || 'Failed to add/update staff', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmDeleteOpen = (staffId) => {
    setDeleteIndex(staffId);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
    setDeleteIndex(null);
  };

  const handleDeleteStaff = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);

    try {
      if (!deleteIndex) {
        throw new Error('Invalid product selected for deletion');
      }

      const response = await fetch(`${apiurl}staff/${deleteIndex}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

   
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete Staff');
      }

      console.log('Staff deleted successfully');
      
      // Update the Staff list
      setStaffList((prevStaffList) => {
        const updatedList = prevStaffList.filter((staff) => staff._id !== deleteIndex);
        
        // If the current page is now empty and it's not the first page, go to the previous page
        if (updatedList.length === 0 && currentPage > 1) {
          setCurrentPage(prev => prev - 1);
        }
        
        return updatedList;
      });

      // Fetch the updated Staff list
      fetchStaffs();

      showAlert('Staff deleted successfully', 'success');

    } catch (error) {
      console.error('Error deleting Staff:', error);
      showAlert(`Failed to delete Staff: ${error.message}`, 'error');
    } finally {
      setConfirmDeleteOpen(false);
      setDeleteIndex(null);
      setIsLoading(false);
    }
  };
  const handleStaffEdit = (staffId, index) => {
    setStaffId(staffId);
  
    const staffToEdit = staffList.find(staff => staff.staffId === staffId);
  
    if (staffToEdit) {
      // Format the DOB
      let formattedDob = staffToEdit.dob;
      if (staffToEdit.dob) {
        // Try to parse the date with moment
        const parsedDate = moment(staffToEdit.dob, [
          'DD-MM-YYYY',  // Format in your data
          'YYYY-MM-DD',  // ISO format
          moment.ISO_8601 // Fallback to any ISO 8601 format
        ], true);
        
        if (parsedDate.isValid()) {
          formattedDob = parsedDate.format('YYYY-MM-DD');
        } else {
          console.warn(`Invalid date format for DOB: ${staffToEdit.dob}`);
          formattedDob = ''; // Set to empty string if invalid
        }
      }
      // Dispatch the staff details to the form
      dispatch({
        type: 'SET_FORM_VALUES',
        payload: {
          firstName: staffToEdit.firstName || '',
          lastName: staffToEdit.lastName || '',
          contact: staffToEdit.contact || '',
          gender: staffToEdit.gender || '',
          dob: formattedDob,
          email: staffToEdit.email || '',
          address1: staffToEdit.address1 || '',
          address2: staffToEdit.address2 || '',
          password: staffToEdit.password || '',
          state: staffToEdit.state || '',
          city: staffToEdit.city || '',
          pinCode: staffToEdit.pinCode || '',
          roleId: staffToEdit.roleId || '',
        }
      });
  
      // Handle store details
      if (staffToEdit.storeDetails) {
        let storeNames = [];
        if (Array.isArray(staffToEdit.storeDetails)) {
          storeNames = staffToEdit.storeDetails.map(store => store.store_id);
        } else if (typeof staffToEdit.storeDetails === 'object') {
          storeNames = [staffToEdit.storeDetails.store_id];
        }
        dispatch({
          type: 'SET_FORM_VALUES',
          payload: { storeNames: storeNames }
        });
      } else {
        dispatch({
          type: 'SET_FORM_VALUES',
          payload: { storeNames: [] }
        });
      }
  
      // Show the form and set the index being edited
      setShowForm(true);
      setEditIndex(staffList.findIndex(staff => staff.staffId === staffId));
    } else {
      console.error('Staff member not found');
      // Optionally, show an error message to the user
    }
  };

const handleFromDateChange = (e) => {
  setFromDate(e.target.value);
};

const handleToDateChange = (e) => {
  setToDate(e.target.value);
};


//states define api
const fetchStates = async () => {
  const token = localStorage.getItem('token');

  try {
    // console.log('Fetching states...');
    const response = await fetch(`${apiurl}state`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    });
    // console.log('Response status:', response.status);
    if (!response.ok) {
      throw new Error('Failed to fetch state');
    }
    const data = await response.json();
    // console.log('Fetched state data:', data);
    if (Array.isArray(data) && data.length > 0) {
      // console.log('First state item:', data[0]);
    } else {
      // console.log('Data is not an array or is empty');
    }
    setStates(data);
  } catch (error) {
    console.error('Error fetching state:', error);
  }
};

useEffect(() => {
  // console.log('Calling fetchStates');
  fetchStates();
}, []);



// In the fetchRoles function:
const fetchRoles = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${apiurl}role`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },

    });
    if (!response.ok) {
      throw new Error('Failed to fetch roles');
    }
    const data = await response.json();
    // Assuming the API returns an array of roles with 'id' and 'role' properties
    setRoles(data.map((role, index) => ({
      id: role.roleId,
      role: role.role,
      roleNumber: index + 1 // Or use role.id if it's already a number
    })));
  } catch (error) {
    console.error('Error fetching roles:', error);
  }
};
useEffect(() => {
  fetchRoles();
}, []);


//===============================store define api==================================================================
const fetchstores = async () => {
  const token = localStorage.getItem('token');

  try {
    const response = await fetch(`${apiurl}store`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },

    });
    if (!response.ok) {
      throw new Error('Failed to fetch stores');
    }
    const data = await response.json();
    // console.log('Fetched store data:', data);

    if (data.status === 'success' && Array.isArray(data.stores)) {
      const storeList = data.stores.map(store => ({
        id: store.storeId, // या store.id, जो भी आपके API में उपयोग हो रहा है
        storeName: store.storeName,
        city: store.city
      }));
      setStores(storeList);
    } else {
      console.error('Unexpected data format:', data);
      setStores([]);
    }
  } catch (error) {
    console.error('Error fetching stores:', error);
    setStores([]);
  }
};
useEffect(() => {
  fetchstores();
}, []);

useEffect(() => {
  // console.log('Updated stores:', stores);
}, [stores]);


return (
  <div className={styles.container}>
    <div className={styles.maincontainer}>
      {isLoading && <LoadingOverlay />}
      <Grid container spacing={2}>
        <Grid item>
          <TextField
            name="fromDate"
            label="From Date"
            type="date"
            value={fromDate}
            onChange={handleFromDateChange}
            sx={{
              ...commonTextFieldStyles,
              width: '150px',
            }}
            InputLabelProps={{
              shrink: true // This ensures the label stays above the input value
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            name="toDate"
            label="To Date"
            type="date"
            value={toDate}
            onChange={handleToDateChange}
            sx={{
              ...commonTextFieldStyles,
              width: '150px',
            }}
            InputLabelProps={{
              shrink: true // This ensures the label stays above the input value
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            name="query"
            label="Search by Name or Contact"
            value={query}
            onChange={handleQueryChange}
            InputProps={{
              className: styles.invoiceinput,
            }}
            InputLabelProps={{
              className: styles.invoicelabel,
              shrink: true
            }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={styles.button}
            sx={{
              backgroundColor: '#009999',
              color: '#fff',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#01dcb8',
              },
            }}
            startIcon={<MdSearch />}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={styles.button}
            sx={{
              backgroundColor: '#009999',
              color: '#fff',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#01dcb8',
              },
            }}
            startIcon={<GroupAddIcon />}
            onClick={() => setShowForm(true)}
          >
            Add Staff
          </Button>
        </Grid>
      </Grid>

      <Dialog
        open={showForm}
        onClose={() => {}}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            maxWidth: 'auto',
            maxHeight: 'auto',
            margin: 'auto',
            mt: 0, // Set the margin-top to 0
          },
        }}
        sx={{
          display: 'flex',
          alignItems: 'flex-start', // Align dialog to the top
          justifyContent: 'center',
        }}
      >
        <DialogTitle>{editIndex !== null ? 'Edit Staff' : 'Add Staff'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editIndex !== null ? 'Edit the details of  staff.' : 'Fill in the details of new Staff.'}
          </DialogContentText>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ mt: 2, }}
          >
            <Grid container spacing={2}>

              <Grid item xs={3}>
                <TextField
                  name="firstName"
                  label="First Name"
                  value={state.formValues.firstName}
                  onChange={handleInputChange}
                  error={!!state.errors.firstName}
                  helperText={state.errors.firstName}
                  required
                  fullWidth
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: '#555',
                      '&.Mui-focused': {
                        color: '#009999',
                      },
                      // Hide the default asterisk and apply custom styles
                      '& .MuiFormLabel-asterisk': {
                        color: 'red', // Customize the color of the asterisk
                      },
                    },
                    '& .MuiInputBase-root': {
                      color: '#000', // Text color
                    },
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  value={state.formValues.lastName}
                  onChange={handleInputChange}
                  error={!!state.errors.lastName}
                  helperText={state.errors.lastName}
                  required
                  fullWidth
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: '#555',
                      '&.Mui-focused': {
                        color: '#009999',
                      },
                      // Hide the default asterisk and apply custom styles
                      '& .MuiFormLabel-asterisk': {
                        color: 'red', // Customize the color of the asterisk
                      },
                    },
                    '& .MuiInputBase-root': {
                      color: '#000', // Text color
                    },
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="contact"
                  label="Contact"
                  value={state.formValues.contact}
                  onChange={handleInputChange}
                  error={!!state.errors.contact}
                  helperText={state.errors.contact}
                  required
                  fullWidth
                  inputProps={{
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                    maxLength: 10,
                  }}
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: '#555',
                      '&.Mui-focused': {
                        color: '#009999',
                      },
                      // Hide the default asterisk and apply custom styles
                      '& .MuiFormLabel-asterisk': {
                        color: 'red', // Customize the color of the asterisk
                      },
                    },
                    '& .MuiInputBase-root': {
                      color: '#000', // Text color
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  fullWidth
                  error={!!state.errors.gender}
                >
                  <InputLabel>
                    Gender <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Select
                    name="gender"
                    value={state.formValues.gender || ''}
                    onChange={(e) => handleInputChange(e)}
                    label="Gender"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Male">
                      <em>Male</em>
                    </MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  {state.errors.gender && (
                    <FormHelperText>{state.errors.gender}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* <Grid item xs={3}>
                <TextField
                  label="Photo"
                  value={photoFileName}
                  InputProps={{
                    endAdornment: (
                      <label htmlFor="photo-upload">
                        <input
                          type="file"
                          accept="image/*"
                          id="photo-upload"
                          style={{ display: 'none' }}
                          onChange={handlePhotoChange}
                        />
                        <IconButton component="span">
                          <MdImage />
                        </IconButton>
                      </label>
                    ),
                  }}
                  fullWidth
                />
              </Grid> */}
              <Grid item xs={3}>
                <TextField
                  name="dob"
                  // label="Date of Birth"
                  type="date"
                  value={state.formValues.dob ? moment(state.formValues.dob).format('YYYY-MM-DD') : ''}
                  onChange={(event) => {
                    handleDateChange(event.target.value ? moment(event.target.value).toDate() : null);
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="email"
                  label="Email"
                  type="email"
                  value={state.formValues.email}
                  onChange={handleInputChange}
                  error={!!state.errors.email}
                  helperText={state.errors.email}
                  required
                  fullWidth
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: '#555',
                      '&.Mui-focused': {
                        color: '#009999',
                      },
                      // Hide the default asterisk and apply custom styles
                      '& .MuiFormLabel-asterisk': {
                        color: 'red', // Customize the color of the asterisk
                      },
                    },
                    '& .MuiInputBase-root': {
                      color: '#000', // Text color
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  value={state.formValues.password}
                  onChange={handleInputChange}
                  error={!!state.errors.password}
                  helperText={state.errors.password}
                  required
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                          disabled={editIndex !== null}
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    readOnly: editIndex !== null,
                  }}
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: '#555',
                      '&.Mui-focused': {
                        color: '#009999',
                      },
                      // Hide the default asterisk and apply custom styles
                      '& .MuiFormLabel-asterisk': {
                        color: 'red', // Customize the color of the asterisk
                      },
                    },
                    '& .MuiInputBase-root': {
                      color: '#000', // Text color
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="address1"
                  label="Address1"
                  value={state.formValues.address1}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="address2"
                  label="Address2"
                  value={state.formValues.address2}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth error={!!state.errors.state}>
                  <InputLabel>State</InputLabel>
                  <Select
                    error={!!state.errors.state}
                    helperText={state.errors.state}
                    name="state"
                    value={state.formValues.state}
                    onChange={(e) => {
                      handleInputChange(e);
                      // console.log('State updated:', e.target.value);
                    }}
                    label="State"
                  >
                    <MenuItem value=""><em>None</em></MenuItem>
                    {Array.isArray(states) ? (
                      states.map((stateItem) => (
                        <MenuItem key={stateItem.id || stateItem._id} value={stateItem.state || stateItem.name}>
                          {stateItem.state || stateItem.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value=""><em>No states available</em></MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="city"
                  label="City"
                  value={state.formValues.city}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="pinCode"
                  label="Pincode"
                  value={state.formValues.pinCode}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only numbers and limit to 6 digits
                    if (value === '' || /^[0-9]{0,6}$/.test(value)) {
                      handleInputChange(e);
                    }
                  }}
                  fullWidth
                  inputProps={{
                    pattern: "[0-9]{6}",
                    inputMode: "numeric",
                    maxLength: 6,
                  }}

                />
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth error={!!state.errors.roleId}>
                  <InputLabel>
                    Role <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Select
                    name="roleId"
                    value={state.formValues.roleId || ''}
                    onChange={handleInputChange}
                    label="Role"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {roles.map((role) => (
                      <MenuItem key={role.id || role._id} value={role.id || role._id}>
                        {role.role}
                      </MenuItem>
                    ))}
                  </Select>
                  {state.errors.roleId && (
                    <FormHelperText>{state.errors.roleId}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  error={!!state.errors.storeNames}
                  helperText={state.errors.storeNames}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'black',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'primary.main',
                      },
                    },
                    '& .MuiSelect-multiple': {
                      minHeight: '23px',
                      height: 'auto',
                      maxHeight: '150px',
                      overflowY: 'auto',
                    },
                  }}
                >
                  <InputLabel>
                    Store Name <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Select
                    name="storeName"
                    value={state.formValues.storeNames.map(id =>
                      stores.find(store => store.id === id)?.storeName + '|' + id
                    )}
                    multiple
                    onChange={handleInputChange}
                    label="Store Name "
                    renderValue={(selected) => selected.map(value => value.split('|')[0]).join(', ')}
                  >
                    {stores.map((store) => (
                      <MenuItem key={store.id} value={`${store.storeName}|${store.id}`}>
                        <Checkbox checked={state.formValues.storeNames.includes(store.id)} />
                        <ListItemText primary={store.storeName} />
                      </MenuItem>
                    ))}
                  </Select>
                  {state.errors.storeNames && (
                    <FormHelperText>{state.errors.storeNames}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <DialogActions sx={{ mt: 2, justifyContent: "flex-start" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#009999',
                  color: '#fff',
                  textTransform: 'capitalize',
                  '&:hover': {
                    backgroundColor: '#01dcb8',
                  },
                }}
                startIcon={<AddIcon />}
                onClick={handleAddOrUpdateStaff}
              >
                {editIndex !== null ? 'Update Staff' : 'Add Staff'}
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#e32e2e',
                  color:"fff",
                  textTransform: 'capitalize',
                  '&:hover': {
                    backgroundColor: '#ff0000',
                  },}}

                onClick={() => {
                  dispatch({ type: 'RESET_FORM' });
                  setShowForm(false);
                  setEditIndex(null);
                }}
                startIcon={<CloseIcon/>}
              >
                Cancel
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>

      <FormControl variant="outlined" size="small" sx={{ minWidth: 120, maxHeight: 5, marginBottom: 5, marginTop: 3 }}>
        <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
        <Select
          labelId="rows-per-page-label"
          value={rowsPerPage}
          onChange={handleRowsPerPageChange}
          label="Rows per page"
        >
          {[10, 25, 50, 100].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div className={styles.tablecontainer}>
        <TableContainer component={Paper}>
          <Table className={styles.storetable}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Sr No.</TableCell>
                {/* <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Photo</TableCell> */}
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Name</TableCell>
                {/* <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Last Name</TableCell> */}
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Contact</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Gender</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>DOB</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Email</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Address1</TableCell>
                {/* <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Address2</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>City</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>State</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Pincode</TableCell> */}
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Store</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Date</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {staffList.map((staff, index) => {
                const uniqueKey = `${staff.staffId}_${index}`;
                return (
                  <TableRow
                    key={uniqueKey}
                    style={{
                      backgroundColor: !staff.isActive
                        ? '#ffcccc'
                        : index % 2 === 0
                          ? 'white'
                          : '#f0f0f0'
                    }}
                  >
                    <TableCell style={tableCellStyle}>
                      {(index + 1 + (currentPage - 1) * itemsPerPage).toString().padStart(4, '0')}
                    </TableCell>
                    <TableCell style={tableCellStyle}>{staff.firstName} {staff.lastName}</TableCell>
                    {/* <TableCell style={tableCellStyle}>{staff.lastName}</TableCell> */}
                    <TableCell style={tableCellStyle}>{staff.contact}</TableCell>
                    <TableCell style={tableCellStyle}>{staff.gender}</TableCell>
                    <TableCell style={tableCellStyle}>{staff.dob}</TableCell>
                    <TableCell style={tableCellStyle}>{staff.email}</TableCell>
                    <TableCell style={tableCellStyle}>{staff.address1}</TableCell>
                    {/* <TableCell style={tableCellStyle}>{staff.address2}</TableCell>
                    <TableCell style={tableCellStyle}>{staff.city}</TableCell>
                    <TableCell style={tableCellStyle}>{staff.state}</TableCell>
                    <TableCell style={tableCellStyle}>{staff.pinCode}</TableCell> */}
                    <TableCell style={tableCellStyle}>
                      {staff.storeDetails && Array.isArray(staff.storeDetails)
                        ? staff.storeDetails.map(store => store.storeName).join(', ')
                        : staff.storeDetails && staff.storeDetails.storeName
                          ? Array.isArray(staff.storeDetails.storeName)
                            ? staff.storeDetails.storeName.join(', ')
                            : staff.storeDetails.storeName
                          : ''}
                    </TableCell>
                    <TableCell style={tableCellStyle}>{staff.createdAt}</TableCell>
                    <TableCell style={tableCellStyle}>
                      <IconButton onClick={() => handleStaffEdit(staffList[index].staffId, index + (currentPage - 1) * itemsPerPage, staff.isActive)} >
                        <Edit sx={{color: "#1a9f53"}}  />
                      </IconButton>
                      <IconButton onClick={() => handleConfirmDeleteOpen(index + (currentPage - 1) * itemsPerPage, staff.isActive)} >
                        <Delete sx={{color:"#ff3b59"}} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>

          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            sx={{
              '& .MuiPaginationItem-root': {
                color: '#009999',
                border: '1px solid #ddd',
                margin: '0 4px',
                borderRadius: '4px',
              },
              '& .Mui-selected': {
                backgroundColor: '#009999',
                color: 'white',
              },
            }}
          />
        </Box>
      </div>
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setErrorSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
          Please fill out all required fields.
        </Alert>
      </Snackbar>

      <Dialog
        open={confirmDeleteOpen}
        onClose={handleConfirmDeleteClose}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this staff member ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleConfirmDeleteClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleDeleteStaff} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================alert======================================================= */}

      {alertInfo.open && (
        <Alert
          severity={alertInfo.severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setAlertInfo({ ...alertInfo, open: false })}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          icon={alertInfo.severity === 'success' ? <CheckCircleOutlineIcon /> : <WarningIcon />}
          sx={{
            position: 'fixed',
            top: 16,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 9999,
          }}
        >
          {alertInfo.message}
        </Alert>
      )}
    </div>
  </div>
);
};
export default Staff;