import React, { useState, useEffect } from 'react';
import styles from "../home/dashboard.module.css";
import { Card, CardContent, Typography, Grid, Box, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { GiExpense } from "react-icons/gi";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';
import { MdSearch,  } from 'react-icons/md';
import {  MdShoppingCart, MdAccountBalance } from 'react-icons/md';
import { GiReceiveMoney, GiPayMoney, GiProfit, GiTakeMyMoney, GiWallet } from 'react-icons/gi';
import { FaRupeeSign, FaMobileAlt } from 'react-icons/fa';
const apiurl = process.env.REACT_APP_API_URL;
const Dashboard = () => {
  // Get current date in YYYY-MM-DD format
  const getCurrentDate = () => new Date().toISOString().split('T')[0];


  const [userRole, setUserRole] = useState('');
  const [userStore, setUserStore] = useState('');

  useEffect(() => {
    const role = localStorage.getItem('roleName');
    const storeName = localStorage.getItem('storeName');
    setUserRole(role);

    if (role === 'store' && storeName) {
      setSelectedStore(storeName);
    } else if (role === 'admin' || role === 'super-admin') {
      fetchStores();
    }
  }, []);

  const [financialData, setFinancialData] = useState({
    totalExpenses: 0,
    totalCashSales: 0,
    totalUpiSales: 0,
    totalSales: 0,
    totalPurchases: 0,
    totalCashExpenses: 0,
    totalUpiExpenses: 0,
    totalProfit: 0,
    totalDue: 0,
  });
  
  const [fromDate, setFromDate] = useState(getCurrentDate());
  const [toDate, setToDate] = useState(getCurrentDate());
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [query, setQuery] = useState('');

  const fetchFinancialSummary = async () => {
    if ((!fromDate || !toDate) && !selectedStore) {
      alert("Please select either date range or a store");
      return;
    }
  
    const token = localStorage.getItem('token');
    try {
      const params = new URLSearchParams();
      if (fromDate && toDate) {
        params.append('fromDate', formatDate(fromDate));
        params.append('toDate', formatDate(toDate));
      }
      if (selectedStore) {
        params.append('query', selectedStore);
      }
  
      const response = await fetch(`${apiurl}dailyExpense/getFinancialSummary?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch financial summary');
      }
      const data = await response.json();
      setFinancialData(data);
    } catch (error) {
      console.error('Error fetching financial summary:', error);
      alert('Error fetching financial summary. Please try again.');
    }
  };

  useEffect(() => {
    fetchFinancialSummary();
  }, [fromDate, toDate, selectedStore]);

  const removeCommas = (x) => {
    return x.replace(/,/g, '');
  };

  const fetchStores = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${apiurl}store`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch stores');
      }
      const data = await response.json();
      // console.log('Fetched store data:', data);

      if (data.status === 'success' && Array.isArray(data.stores)) {
        const storeList = data.stores.map(store => ({
          id: store.storeId,
          storeName: store.storeName,
          city: store.city
        }));
        setStores(storeList);
      } else {
        // console.error('Unexpected data format:', data);
        setStores([]);
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
      setStores([]);
    }
  };

  useEffect(() => {
    fetchStores();
  }, []);

  useEffect(() => {
    // console.log('Updated stores:', stores);
  }, [stores]);

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === 'fromDate') {
      setFromDate(value);
    } else if (name === 'toDate') {
      setToDate(value);
    }
  };

  const handleStoreChange = (event) => {
    setSelectedStore(event.target.value);
  };


  const formatDate = (date) => {
    if (typeof date === 'string') {
      return date;
    }
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const role_Name = localStorage.getItem('roleName'); // Assuming 'admin', 'superAdmin', 'store', or 'staff'
  // console.log('User Role:', role_Name);

  const cardData = [
    { label: "Total Sales", value: financialData.totalSales, color: "#505050", icon: <FaRupeeSign /> },
    { label: "Cash Sales", value: financialData.totalCashSales, color: "#996633", icon: <GiReceiveMoney /> },
    { label: "UPI Sales", value: financialData.totalUpiSales, color: "#008000", icon: <FaMobileAlt /> },
    { label: "Total Purchase", value: financialData.totalPurchases, color: "#ff4d4d", icon: <MdShoppingCart /> },
    { label: "Cash Expense", value: financialData.totalCashExpenses, color: "#8000ff", icon: <GiPayMoney /> },
    { label: "UPI Expense", value: financialData.totalUpiExpenses, color: "#ff8000", icon: <FaMobileAlt /> },
    { label: "Total Expenses", value: financialData.totalExpenses, color: "#84009996", icon: <GiTakeMyMoney /> },
    { label: "Total Profit", value: financialData.totalProfit, color: "#74c4c4", icon: <GiProfit /> },
    { 
      label: (role_Name === 'admin' || role_Name === 'super-admin') ? "Liabilities" : "Total Due", 
      value: financialData.totalDue, 
      color: "#3fbe70",
      icon: <MdAccountBalance />
    },
  ];

  const chartData = cardData.map(item => ({
    name: item.label,
    value: typeof item.value === 'string' ? item.value.replace(/,/g, '') : item.value,
  }));

    const commonTextFieldStyles = {
    '& .MuiOutlinedInput-root': {
      height: '40px',
      '& fieldset': {
        borderColor: '#888',
      },
      '&:hover fieldset': {
        borderColor: '#555',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#009999',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#555',
      '&.Mui-focused': {
        color: '#009999',
      },
    },
    '& .MuiInputBase-input': {
      color: '#333',
    },
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box sx={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '4px',
        }}>
          <Typography variant="body2">{`${label} : ${payload[0].value}`}</Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <div className={styles.container}>
    <div className={styles.maincontainer}>
    <Grid container spacing={2}>
      <Grid item>
      <TextField
          type="date"
          name="fromDate"
          label="From Date"
          value={fromDate}
          onChange={handleDateChange}
          InputLabelProps={{ shrink: true }}
          size="small"
          sx={{
              ...commonTextFieldStyles,
              width: '150px',
            }}
        />
        </Grid>
        <Grid item>
        <TextField
          type="date"
          name="toDate"
          label="To Date"
          value={toDate}
          onChange={handleDateChange}
          InputLabelProps={{ shrink: true }}
          size="small"
          sx={{
              ...commonTextFieldStyles,
              width: '150px',
            }}
        />
       </Grid>
       {userRole === 'admin' || userRole === 'super-admin' ? (
        <FormControl size='small' sx={{ margin: 1, minWidth: 100, height: 40,marginTop:2 }}>
          <InputLabel>Store</InputLabel>
          <Select
            value={selectedStore}
            onChange={handleStoreChange}
            label="Store"
          >
            <MenuItem value=""><em>None</em></MenuItem>
            {stores.map((store) => (
              <MenuItem key={store.id} value={store.storeName}>
                {store.storeName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>) : (
            <TextField
              value={userStore}
              disabled
              label="Store"
              size="small"
              sx={{
                margin: 1,
                minWidth: 100,
                height: 40,
                marginTop: 2,
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000',
                },
              }}
            />
          )}
        <Grid item>
        <Button variant="contained" onClick={fetchFinancialSummary} sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
              startIcon={<MdSearch />}
              >
          Search
        </Button>
     </Grid>
     </Grid>

      <Grid container spacing={2} justifyContent="center" sx={{marginTop:3}}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={2.4} key={index}>
            <Card
              sx={{
                width: 200,
                height: 140,
                backgroundColor: card.color,
                color: "#fff",
                boxShadow: "0px 4px 15px rgb(0, 255, 255)",
                borderRadius: "12px",
                transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                '&:hover': {
                  transform: "translateY(-5px)",
                  boxShadow: "0px 8px 25px rgb(0, 255, 255)",
                },
              }}
            >
              <CardContent className={styles.ab}>
                <Grid container alignItems="center">
                  <Grid item xs={3} container justifyContent="center">
                  {React.cloneElement(card.icon, { fontSize: 40, style: { color: "#fff" } })}
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    container
                    direction="column"
                    alignItems="flex-end"
                    justifyContent="center"
                    textAlign="right"
                  >
                    <Typography sx={{ fontSize: 20, fontWeight: "500" }} color="inherit">
                      {card.label}
                    </Typography>
                    <Typography
                      variant="h5"
                      fontSize={18}
                      component="div"
                      color="inherit"
                      marginTop="10px"
                    >
                      {card.value}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box className={styles.chartContainer}>
        <h2 className={styles.title}>Financial Summary</h2>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={chartData}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{ fill: '#666' }} />
            <YAxis tick={{ fill: '#666' }} />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="value"
              stroke="#8884d8"
              strokeWidth={2}
              dot={{
                fill: '#8884d8',
                stroke: '#8884d8',
                strokeWidth: 2,
                r: 6,
                strokeDasharray: '',
              }}
              activeDot={{
                r: 8,
                stroke: '#8884d8',
                strokeWidth: 2,
                fill: '#fff',
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </div>
    </div>
  );
}

export default Dashboard;
